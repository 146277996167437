.search-results-list {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search-results-list__card {
  width: 100%;
  border-top: 1px solid var(--neutral-line-primary-opacity);
  padding-top: 16px;
}

.search-results-list__card:last-child {
  border-bottom: 1px solid var(--neutral-line-primary-opacity);
  padding-bottom: 16px;
}

.search-results-list__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.search-results-list__text {
  color: var(--neutral-tertiary);
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.17;
  white-space: nowrap;
}

@media screen and (max-width: 780px) {
  .search-results-list__text {
    font-size: 10px;
    line-height: 1.2;
  }
}
