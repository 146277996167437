.header {
  width: 100%;
  max-width: calc(var(--max-width) + 128px);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: var(--website-background-theme);
  box-sizing: border-box;
  padding: 40px 64px 12px;
  transition: transform 0.3s ease-in-out, backdrop-filter 0.2s ease-in-out;
  z-index: 100;
}

.header_hide {
  transform: translateY(-100%);
}

.header_show {
  transform: translateY(0);
}

.header_theme_dark {
  background-color: transparent;
  backdrop-filter: blur(0px);
}

.header_theme_dark.header_type_blur {
  backdrop-filter: blur(17px);
}

.header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__content-block {
  display: flex;
  align-items: center;
}

.header__icon-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.header__icon-btn_type_logo {
  background-color: var(--neutral-active-theme);
}

.header__icon-btn_type_burger {
  display: none;
}

.header__icon-btn_type_creator {
  border: none;
  background-color: var(--main-active-primary);
}

.header__icon-btn_type_favorites {
  border: none;
  background-color: var(--neutral-bg-primary);
}

.header__icon-btn_theme_dark {
  background-color: var(--neutral-active-theme);
}

.header__icon-link_active {
  pointer-events: none;
  user-select: none;
}

.header__icon-link_active .header__icon-btn {
  background-color: var(--neutral-active-theme);
}

.header__logo-icon {
  width: 20px;
  height: 14px;
}

.header__logo-icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.header__burger-icon {
  width: 16px;
  height: 14px;
}

.header__search-icon {
  width: 24px;
  height: 24px;
}

.header__bookmark-icon {
  width: 12px;
  height: 15px;
}

.header__icon-fill {
  fill: var(--neutral-active-theme);
  transition: fill 0.3s ease-in-out;
}

.header__icon-link_active .header__icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.header__icon-btn_theme_dark .header__icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.header__pen-icon {
  width: 14px;
  height: 21px;
}

.header__pen-icon-fill {
  fill: var(--neutral-contrast-active);
}

.header__catalog-box {
  position: relative;
}

.header__nav-list {
  display: flex;
  align-items: center;
}

.header__btn {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 60px;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
  padding: 10px 23px;
  transition: all 0.3s ease-in-out;

  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28;
}

.header__btn_inverted {
  background: var(--neutral-active-theme);
  color: var(--neutral-contrast-active-theme);
}

.header__nav-link_active .header__btn {
  color: var(--neutral-contrast-active-theme) !important;
  background: var(--neutral-active-theme) !important;
}

.header__btn_active {
  color: var(--neutral-contrast-active-theme);
  background: var(--neutral-active-theme);
}

.header__arrow-box {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__arrow {
  width: 100%;
  height: 100%;
}

.header__arrow-fill {
  fill: var(--neutral-active-theme);
  transition: fill 0.3s ease-in-out;
}

.header__arrow-fill_active {
  fill: var(--neutral-contrast-active-theme);
}

.header__avatar-box {
  position: relative;
}

.header__avatar-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.header__balance-block {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 20px;
}

.header__balance-label {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  opacity: 0.6;
}

.header__balance-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header__balance-icon {
  width: 18px;
  height: 18px;
}

.header__balance-amount {
  color: var(--neutral-active-theme);
  min-width: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28;
}

.header__add-btn {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__add-icon {
  width: 100%;
  height: 100%;
}

.header__add-icon-fill {
  fill: var(--second-active-tertiary);
}

.header__add-icon-stroke {
  stroke: var(--neutral-contrast-active);
}

@media screen and (max-width: 1440px) {
  .header {
    padding: 40px 32px 12px;
  }

  .header__container {
    max-width: var(--container-max-width);
  }
}

@media screen and (max-width: 1100px) {
  .header {
    padding: 30px 32px 12px;
    transition: transform 0.3s ease-in-out, z-index 0s linear 0.6s,
      backdrop-filter 0.2s ease-in-out;
  }

  .header_z-index_popup {
    z-index: 0;
    transition: transform 0.3s ease-in-out, z-index 0s linear,
      backdrop-filter 0.2s ease-in-out;
  }

  .header__balance-block {
    gap: 8px;
    margin-right: 12px;
  }

  .header__balance-label {
    display: none;
  }

  .header__balance-box {
    gap: 6px;
  }
}

@media screen and (max-width: 780px) {
  .header {
    padding: 16px 16px 12px;
  }

  .header__icon-btn_type_burger {
    display: flex;
  }

  .header__btn_catalog {
    display: none;
  }

  .header__nav {
    display: none;
  }
}
