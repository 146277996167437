.paywall {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.paywall__container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background-color: var(--paywall-bg-color);
}

.paywall__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  position: relative;
  background-image: url('../../../assets/images/paywall/ellipses.png'),
    url('../../../assets/images/paywall/star.png'),
    url('../../../assets/images/paywall/circles.png');
  background-repeat: no-repeat;
  background-position: left -100px center, left 93px center, right;
  background-size: 216px 416px, 80px 80px, contain;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1;
}

.paywall__title {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.15;
}

.paywall__pages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paywall__pages-number {
  color: var(--main-active-primary);
  text-align: center;
  font-family: Oswald;
  font-size: 56px;
  font-weight: 200;
  line-height: 1.04;
}

.paywall__pages-text {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.paywall__button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 600px;
  background: var(--main-active-primary);
  box-sizing: border-box;
  padding: 14px 24px;

  color: var(--neutral-contrast-active);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.paywall__button-icon {
  width: 20px;
  height: 20px;
}

.paywall__button-icon-stroke {
  stroke: var(--neutral-contrast-active);
}

.paywall__bg {
  width: 170px;
  height: 170px;
  position: absolute;
  bottom: -6px;
  border-radius: 50%;
  filter: blur(147px);
  z-index: 0;
}

.paywall__bg_position_left {
  background-color: #92b7ff;
  left: 48px;
}

.paywall__bg_position_right {
  background-color: #dbb1fb;
  right: -9px;
}

.paywall__arrow {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.paywall__arrow-fill {
  fill: var(--second-active-primary);
}

.paywall__text {
  color: var(--paywall-text-color);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
  filter: blur(2px);
  padding: 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.paywall__divider {
  width: 100%;
  margin-top: 20px;
}

@media screen and (max-width: 780px) {
  .paywall {
    gap: 16px;
  }

  .paywall__container {
    background-image: url('../../../assets/images/paywall/ellipses.png');
    background-repeat: no-repeat;
    background-position: left -85px center;
    background-size: 143px 276px;
  }

  .paywall__content {
    gap: 18px;
    background-image: url('../../../assets/images/paywall/star.png'),
      url('../../../assets/images/paywall/circles.png');
    background-position: left 48px center, right top 60px;
    background-size: 38px 38px, 66px 132px;
    padding: 24px 16px 16px;
  }

  .paywall__title {
    font-size: 22px;
    line-height: 1.09;
  }

  .paywall__pages-number {
    font-size: 56px;
  }

  .paywall__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    padding: 0;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .paywall__divider {
    margin-top: 14px;
  }
}
