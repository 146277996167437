.search-dropdown-creator {
  width: 100%;
  display: flex;
}

.search-dropdown-creator__container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.search-dropdown-creator__image {
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
}

.search-dropdown-creator__title {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}
