.ai-stories {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;
  box-sizing: border-box;
  padding: 28px 64px 100px;
}

.ai-stories__title {
  color: var(--neutral-active-theme);
  font-size: 60px;
  line-height: 1.06;
  letter-spacing: -1.2px;
  text-transform: uppercase;
  z-index: 1;
}

.ai-stories__title_type_color {
  color: var(--main-active-primary);
}

.ai-stories__swiper {
  position: relative;
  margin-top: 42px;
  z-index: 1;
}

.ai-stories__bg-box {
  width: 100vw;
  height: calc(100% + 120px + 538px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: -120px;
  background-color: var(--website-background-theme);
  box-sizing: border-box;
  padding: 120px 64px 538px;
  z-index: 0;
}

.ai-stories__bg-box-wrapper {
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ai-stories__bg {
  width: 610px;
  opacity: 0.6;
  mix-blend-mode: soft-light;
  position: absolute;
  top: -250px;
  z-index: 1;
}

.ai-stories__circle {
  width: 483px;
  height: 483px;
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  opacity: 0.2;
  z-index: 0;
}

.ai-stories__circle_position_top-left {
  width: 476px;
  height: 476px;
  background-color: var(--ai-color-accent-primary);
  top: -58px;
  left: -228px;
}

.ai-stories__circle_position_top-right {
  background-color: var(--ai-color-accent-secondary);
  top: 181px;
  right: -241px;
}

.ai-stories__circle_position_bottom {
  background-color: var(--ai-color-accent-tertiary);
  bottom: -127px;
  left: 206px;
}

@media screen and (max-width: 1440px) {
  .ai-stories {
    max-width: calc(var(--container-max-width) + 32px * 2);
    padding: 28px 32px 100px;
  }

  .ai-stories__bg-box {
    padding: 120px 32px 538px;
  }

  .ai-stories__bg-box-wrapper {
    max-width: calc(var(--container-max-width) + 32px * 2);
  }
}

@media screen and (max-width: 1100px) {
  .ai-stories {
    gap: 30px;
    padding: 28px 32px 80px;
  }

  .ai-stories__title {
    font-size: 48px;
    letter-spacing: -0.96px;
  }

  .ai-stories__swiper {
    margin-top: 30px;
  }
}

@media screen and (max-width: 780px) {
  .ai-stories {
    gap: 20px;
    padding: 28px 16px 40px;
  }

  .ai-stories__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .ai-stories__swiper {
    margin-top: 20px;
  }

  .ai-stories__bg-box {
    height: 100%;
    top: -96px;
    padding: 96px 16px 538px;
  }

  .ai-stories__bg {
    width: 358px;
    top: -200px;
  }

  .ai-stories__circle {
    display: none;
  }
}
