.reader-controls {
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  background-color: var(--website-background-theme);
  box-sizing: border-box;
  padding-bottom: 20px;
  z-index: 10;
}

.reader-controls__btns {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 110px;
  background: var(--website-background-theme);
  box-shadow: 0px 14px 24px 0px var(--box-shadow-primary-theme);
  box-sizing: border-box;
  padding: 4px;
}

.reader-controls__btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--neutral-bg-primary);
}

.reader-controls__heart-icon {
  width: 16px;
  height: 14px;
}

.reader-controls__comment-icon {
  width: 14px;
  height: 15px;
}

.reader-controls__icon-fill {
  fill: var(--neutral-active);
  transition: fill 0.2s ease-in-out;
}

.reader-controls__icon-fill_active {
  fill: var(--neutral-contrast-active);
}

.reader-controls__gradient {
  width: 100vw;
  height: 152px;
  background: linear-gradient(
    180deg,
    var(--neutral-bg-opacity),
    var(--website-background-theme) 50%
  );
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

@media screen and (max-width: 780px) {
  .reader-controls__gradient {
    height: 112px;
    background: linear-gradient(
      180deg,
      var(--neutral-bg-opacity),
      var(--website-background-theme) 41.59%
    );
  }
}
