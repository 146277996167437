.auth-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  box-sizing: border-box;
  padding: 30px 40px 20px;
}

.auth-wrapper__heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.auth-wrapper__title-block {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.auth-wrapper__title-block_align_center {
  justify-content: center;
}

.auth-wrapper__logo {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--neutral-active-theme);
  box-sizing: border-box;
}

.auth-wrapper__logo_type_google {
  background-color: transparent;
  border: 1px solid var(--neutral-border-secondary);
}

.auth-wrapper__google-icon {
  width: 20px;
  height: 20px;
}

.auth-wrapper__logo-icon {
  width: 20px;
  height: 14px;
}

.auth-wrapper__logo-icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.auth-wrapper__title {
  color: var(--neutral-active-theme);
  font-size: 38px;
  font-weight: 300;
  line-height: 1.05;
}

.auth-wrapper__title_size_small {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.25;
}

.auth-wrapper__subtitle {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
}

.auth-wrapper__subtitle_size_small {
  font-size: 14px;
}

.auth-wrapper__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.auth-wrapper__link-text {
  color: var(--auth-color-primary);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

@media screen and (max-width: 780px) {
  .auth-wrapper {
    flex: auto;
    padding: 96px 32px 0;
  }
}
