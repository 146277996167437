.comments {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.comments__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.comments__list_nested {
  gap: 8px;
}

.comments__text {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28;
  margin: auto 0;
}

.comments__more-button {
  width: fit-content;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: transparent;
  transition: opacity 0.2s ease-in-out;
  cursor: default;
}

.comments__more-button_type_reply {
  border-radius: 40px;
  background-color: var(--website-background-theme);
  box-sizing: border-box;
  padding: 6px 8px;
  margin-left: 82px;
  cursor: pointer;

  color: var(--second-active-tertiary);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.22;
}
