.mobile-menu {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--popup-background);
  z-index: 10000;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.mobile-menu__container {
  width: 100%;
  max-width: 310px;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 0 30px 30px 0;
  background: var(--website-background-theme);
}

.mobile-menu__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  padding: 16px 16px 30px;
}

.mobile-menu__heading {
  width: 100%;
  display: flex;
  align-items: center;
}

.mobile-menu__icon-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
}

.mobile-menu__icon-btn_type_close {
  border: none;
  background-color: var(--neutral-bg-opacity-primary);
  margin-left: auto;
}

.mobile-menu__logo-icon {
  width: 20px;
  height: 14px;
}

.mobile-menu__close-icon {
  width: 12px;
  height: 12px;
}

.mobile-menu__icon-fill {
  fill: var(--neutral-active-theme);
}

.mobile-menu__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
  padding: 44px 0 0 56px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobile-menu__content::-webkit-scrollbar {
  display: none;
}

.mobile-menu__links-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: -18px;
}

.mobile-menu__item {
  width: fit-content;
  will-change: opacity, transform;
}

.mobile-menu__link {
  display: flex;
  position: relative;
}

.mobile-menu__btn {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.mobile-menu__btn-icon {
  width: 10px;
  height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.mobile-menu__link_active .mobile-menu__btn-icon {
  opacity: 1;
}

.mobile-menu__btn-icon-fill {
  fill: var(--neutral-active-theme);
}

.mobile-menu__btn-text {
  color: var(--neutral-active-theme);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  white-space: nowrap;
}

.mobile-menu__divider-wrapper {
  width: calc(100% - 6px);
}

.mobile-menu__social-list {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.mobile-menu__social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.mobile-menu__link-icon {
  width: 100%;
  height: 100%;
}

.mobile-menu__link-icon-background {
  fill: var(--website-background-theme);
}

.mobile-menu__link-icon-fill {
  fill: var(--neutral-active-theme);
}

.mobile-menu__link-icon-stroke {
  stroke: var(--neutral-active-theme);
}

.mobile-menu__copyright {
  color: var(--neutral-quaternary);
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4;
}

.mobile-menu__dots-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 310px;
}

.mobile-menu__dots {
  width: 52px;
  height: 490px;
  display: grid;
  grid-template-columns: repeat(3, 6px);
  grid-template-rows: repeat(12, 6px);
  justify-content: space-between;
  align-content: space-between;
  margin-left: 14px;
}

.mobile-menu__dot {
  width: 6px;
  height: 6px;
  background: var(--second-active-quaternary);
  border-radius: 50%;
}
