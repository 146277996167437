.search-dropdown-story {
  width: 100%;
  display: flex;
}

.search-dropdown-story__container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  overflow: hidden;
}

.search-dropdown-story__container_with-border {
  border-radius: 6px;
  border: 1px solid var(--neutral-border-primary-theme);
}

.search-dropdown-story__image {
  width: 70px;
  height: 84px;
  object-fit: cover;
  border-radius: 6px;
}

.search-dropdown-story__container_with-border .search-dropdown-story__image {
  border-radius: 0;
}

.search-dropdown-story__info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
  padding-right: 24px;
}

.search-dropdown-story__subtitle {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.16;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.search-dropdown-story__title {
  max-width: 150px;
  color: var(--neutral-active-theme);
  font-family: Oswald;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.22;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
