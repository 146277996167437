.creator {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  box-sizing: border-box;
  padding: 28px 64px 100px;
}

.creator__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

@media screen and (max-width: 1440px) {
  .creator {
    max-width: calc(var(--container-max-width) + 32px * 2);
    min-height: 100dvh;
    padding: 28px 32px 100px;
  }
}

@media screen and (max-width: 1100px) {
  .creator {
    padding: 28px 32px 80px;
  }

  .creator__content {
    gap: 40px;
  }
}

@media screen and (max-width: 780px) {
  .creator {
    gap: 20px;
    padding: 8px 16px 40px;
  }
}
