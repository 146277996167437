.text-element-title {
  color: var(--neutral-active-theme);
  font-weight: 300;
}

.text-element-title_size_l {
  font-size: 38px;
  line-height: 1.15;
}

.text-element-title_size_m {
  font-size: 30px;
  line-height: 1.13;
}

.text-element-title_size_s {
  font-size: 24px;
  line-height: 1.16;
}

.text-element-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  color: var(--neutral-active-theme);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

@media screen and (max-width: 780px) {
  .text-element-title_size_l {
    font-size: 34px;
    line-height: 1.11;
  }

  .text-element-title_size_m {
    font-size: 28px;
    line-height: 1.14;
  }

  .text-element-title_size_s {
    font-size: 22px;
    line-height: 1.18;
  }

  .text-element-text {
    font-size: 14px;
    line-height: 1.428;
  }
}
