.catalog-dropdown {
  width: 85vw;
  max-width: 1018px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 780px) {
  .catalog-dropdown {
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 24px;
    box-sizing: border-box;
    padding: 24px 32px;
  }

  .catalog-dropdown__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .catalog-dropdown__title {
    color: var(--neutral-active-theme);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }

  .catalog-dropdown__nav-list {
    display: flex;
    align-items: center;
  }

  .catalog-dropdown__nav-item {
    margin: 0;
  }

  .catalog-dropdown__nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 600px;
    background: var(--neutral-bg-opacity-primary);
    box-sizing: border-box;
    padding: 6px 14px;
    transition: all 0.3s ease-in-out;

    color: var(--neutral-active-theme);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.16;
  }

  .catalog-dropdown__nav-link_active {
    background: var(--neutral-active-theme);
    color: var(--neutral-contrast-active-theme);
  }

  .catalog-dropdown__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: auto;
    border-top: 1px solid var(--neutral-line-primary-opacity);
    box-sizing: border-box;
    padding-top: 24px;
  }
}
