.search-bar {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 12px;
  position: relative;
  border-radius: 60px;
  background-color: var(--website-background-theme);
  z-index: 1000;
}

.search-bar_type_page {
  z-index: 1;
}

.search-bar__container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 60px;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
  overflow: hidden;
}

.search-bar__icon-container {
  display: flex;
  position: absolute;
  left: 8px;
  z-index: 0;
}

.search-bar__search-icon {
  width: 24px;
  height: 24px;
}

.search-bar__search-icon-fill {
  fill: var(--neutral-active-theme);
}

.search-bar__input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 12px 12px 12px 44px;
  position: relative;
  z-index: 1;

  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.search-bar__input::placeholder {
  color: var(--neutral-line-primary-theme);
}

.search-bar__close-btn {
  width: 100%;
  max-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--neutral-bg-opacity-primary);
}

.search-bar__close-icon {
  width: 14px;
  height: 14px;
}

.search-bar__close-icon-fill {
  fill: var(--neutral-active-theme);
}

@media screen and (max-width: 780px) {
  .search-bar {
    width: calc(100% + 16px - 34px) !important;
    margin-left: -16px;
  }

  .search-bar_type_page {
    width: 100% !important;
    margin-left: 0;
  }

  .search-bar__icon-container {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    left: unset;
    right: 0;
    border-radius: 50%;
    background-color: var(--neutral-active-theme);
  }

  .search-bar__search-icon-fill {
    fill: var(--neutral-contrast-active-theme);
  }

  .search-bar__input {
    padding: 12px 44px 12px 16px;
  }

  .search-bar__close-btn {
    display: none;
  }
}
