.swiper-block {
  width: 100vw;
  height: 100%;
  max-width: var(--max-width);
  display: grid;
  position: relative;
  align-items: center;
}

.swiper-block_type_reader {
  max-width: 100%;
}

.swiper-block__swiper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 64px !important;
}

.swiper-block__swiper_type_reader {
  box-sizing: border-box;
  padding: 0 !important;
}

.swiper-block__slide {
  width: fit-content !important;
}

.swiper-block__nav-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  background: var(--neutral-bg-opacity-secondary);
  box-shadow: 0px 10px 16px 0px var(--box-shadow-secondary);
  backdrop-filter: blur(5px);
  z-index: 3;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.swiper-block:hover .swiper-block__nav-button {
  opacity: 1;
}

.swiper-block__nav-button_disabled {
  opacity: 0 !important;
}

.swiper-block__nav-button:disabled {
  opacity: 0 !important;
}

.swiper-block__nav-button_type_prev {
  left: 34px;
  transform: scaleX(-1);
}

.swiper-block__nav-button_type_reader-prev {
  left: -30px;
}

.swiper-block__nav-button_type_next {
  right: 34px;
}

.swiper-block__nav-button_type_reader-next {
  right: -30px;
}

.swiper-block__nav-icon {
  width: 11px;
  height: 20px;
}

.swiper-block__nav-icon-fill {
  fill: var(--neutral-active);
}

.swiper-block__gradient {
  width: 92px;
  height: calc(100% + 40px);
  object-fit: fill;
  position: absolute;
  z-index: 2;
}

.swiper-block__gradient_position_left {
  left: -4px;
  transform: scaleX(-1);
}

.swiper-block__gradient_position_right {
  right: -4px;
}

@media (pointer: coarse) {
  .swiper-block__nav-button {
    opacity: 1;
  }
}

@media screen and (max-width: 1440px) {
  .swiper-block__swiper {
    padding: 0 var(--swiper-padding-horizontal) !important;
  }

  .swiper-block__swiper_type_reader {
    padding: 0 !important;
  }

  .swiper-block__nav-button_type_prev {
    left: 70px;
  }

  .swiper-block__nav-button_type_reader-prev {
    left: -30px;
  }

  .swiper-block__nav-button_type_next {
    right: 70px;
  }

  .swiper-block__nav-button_type_reader-next {
    right: -30px;
  }
}

@media screen and (max-width: 1100px) {
  .swiper-block__swiper {
    padding: 0 32px !important;
  }

  .swiper-block__swiper_type_reader {
    padding: 0 !important;
  }

  .swiper-block__nav-button {
    width: 48px;
    height: 48px;
  }

  .swiper-block__nav-button_type_prev {
    left: 24px;
  }

  .swiper-block__nav-button_type_reader-prev {
    left: -24px;
  }

  .swiper-block__nav-button_type_next {
    right: 24px;
  }

  .swiper-block__nav-button_type_reader-next {
    right: -24px;
  }

  .swiper-block__gradient {
    display: none;
  }
}

@media screen and (max-width: 824px) {
  .swiper-block_type_reader {
    max-width: var(--max-width);
  }

  .swiper-block__swiper_type_reader {
    border-radius: 0;
    border-right: none;
    border-left: none;
    padding: 0 32px !important;
  }

  .swiper-block__nav-button_type_prev {
    left: 24px;
  }

  .swiper-block__nav-button_type_next {
    right: 24px;
  }
}

@media screen and (max-width: 780px) {
  .swiper-block {
    display: flex;
    overflow: scroll;
  }

  .swiper-block::-webkit-scrollbar {
    display: none;
  }

  .swiper-block__cards {
    height: 365px;
    display: flex;
    gap: var(--cards-gap);
    padding: 0 16px;
  }

  .swiper-block__cards_type_small {
    height: 320px;
  }

  .swiper-block__cards_type_continue {
    height: 120px;
  }

  .swiper-block__nav-button {
    display: none;
  }
}
