.catalog-page {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
  padding: 28px 64px 100px;
}

.catalog-page_type_block {
  padding: 0;
}

.catalog-page_type_custom {
  padding: 60px 64px 100px;
}

.catalog-page__title {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.catalog-page__title span {
  color: var(--main-active-primary);
}

.catalog-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

.catalog-page__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  gap: 32px 16px;
}

.catalog-page__list_cards_small {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.catalog-page__more-button {
  width: fit-content;
  color: transparent;
  transition: opacity 0.2s ease-in-out;
  cursor: default;
}

.catalog-page__preloader {
  width: fit-content;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalog-page__text {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
}

@media screen and (max-width: 1440px) {
  .catalog-page {
    max-width: calc(var(--container-max-width) + 32px * 2);
    min-height: 100dvh;
    padding: 28px 32px 100px;
  }

  .catalog-page_type_block {
    max-width: 100%;
    min-height: unset;
    padding: 0;
  }

  .catalog-page_type_custom {
    padding: 60px 32px 100px;
  }

  .catalog-page__list {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .catalog-page__list_cards_small {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 1100px) {
  .catalog-page {
    padding: 28px 32px 80px;
  }

  .catalog-page_type_block {
    padding: 0;
  }

  .catalog-page_type_custom {
    padding: 40px 32px 80px;
  }

  .catalog-page__title {
    font-size: 48px;
    letter-spacing: -0.96px;
  }

  .catalog-page__content {
    gap: 40px;
  }

  .catalog-page__list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 24px 12px;
  }

  .catalog-page__list_cards_small {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 16px 10px;
  }
}

@media screen and (max-width: 780px) {
  .catalog-page {
    padding: 28px 20px 40px;
  }

  .catalog-page_type_block {
    padding: 0;
  }

  .catalog-page__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .catalog-page__list {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    gap: 10px;
  }
}
