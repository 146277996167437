@import url(./assets/fonts/Inter/stylesheet.css);

html {
  scrollbar-gutter: stable;
  overflow: scroll;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  font-style: normal;
  font-weight: 400;
  white-space: pre-line;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Oswald;
  font-weight: 200;
}

span {
  color: inherit;
  white-space: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: Inter, sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea, label {
  font-family: Inter, sans-serif;
  font-style: normal;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  font-family: Inter, sans-serif;
  font-style: normal;
  -webkit-box-shadow: 0 0 0 500px var(--website-background) inset !important;
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--website-background) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

[popover] {
  background-color: transparent;
  border: none;
  padding: 0;
}

[popover]::backdrop {
  background-color: var(--popup-background);
}

.skeleton-loader {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(
      to right,
      var(--neutral-bg-opacity),
      var(--neutral-bg-opacity-secondary) 50%,
      var(--neutral-bg-opacity) 100%
    ),
    var(--neutral-bg-primary);
  background-repeat: repeat-y;
  background-size: 70% 100%;
  background-position: -200% 0;
  animation: shine 1.5s infinite linear;
}

.skeleton-loader_theme_dark {
  background: linear-gradient(
      to right,
      var(--neutral-bg-opacity-dark-theme),
      var(--neutral-bg-opacity-secondary-dark-theme) 50%,
      var(--neutral-bg-opacity-dark-theme) 100%
    ),
    var(--neutral-bg-opacity-primary-dark-theme);
    background-repeat: repeat-y;
    background-size: 70% 100%;
    background-position: -200% 0;
}

@keyframes shine {
  to {
    background-position: 300% 0, /* move highlight to right */ 0 0;
  }
}

:root {
  --max-width: 1682px;
  --container-max-width: 1240px;
  --reader-max-width: 760px;
  --desk-content-max-width: 736px;

  --website-background: #FFFFFF;
  --website-background-dark-theme: #2E1F47;
  --popup-background: rgba(92, 103, 131, 0.83);
  --popup-background-transparent: rgba(92, 103, 131, 0);

  --block-background-primary: #F5FBFF;
  --block-background-secondary: #FFF3F6;
  --block-background-tertiary: #FAF6FF;
  --block-background-quaternary: rgba(46, 31, 71, 0.16);

  --neutral-active: #1D0E36;
  --neutral-contrast-active: #FFFFFF;
  --neutral-primary: #8D99A5;
  --neutral-secondary: #665D73;
  --neutral-tertiary: #8092A3;
  --neutral-quaternary: #766F82;

  --neutral-active-dark-theme: #C0D1E4;
  --neutral-contrast-active-dark-theme: #2E1F47;

  --neutral-bg-inactive: #C0CCD8;
  --neutral-bg-primary: #F3F5FA;
  --neutral-bg-secondary: #F8F8F9;

  --neutral-bg-opacity: rgba(255, 255, 255, 0);
  --neutral-bg-opacity-primary: rgba(202, 202, 216, 0.30);
  --neutral-bg-opacity-secondary: rgba(255, 255, 255, 0.81);
  --neutral-bg-opacity-tertiary: rgba(255, 255, 255, 0.02);

  --neutral-bg-opacity-dark-theme: rgba(46, 31, 71, 0);
  --neutral-bg-opacity-primary-dark-theme: rgba(46, 31, 71, 0.30);
  --neutral-bg-opacity-secondary-dark-theme: rgba(46, 31, 71, 0.81);

  --neutral-border-primary: #DFE4EA;
  --neutral-border-secondary: #DADCE0;
  --neutral-border-tertiary: #F0F3F8;

  --neutral-border-primary-dark-theme: #716782;

  --neutral-line-primary: #CACAD8;
  --neutral-line-primary-opacity: rgba(202, 202, 216, 0.40);
  --neutral-line-secondary: #E7EDF3;

  --neutral-line-primary-dark-theme: #716782;

  --box-shadow-primary: rgba(136, 155, 173, 0.24);
  --box-shadow-secondary: rgba(93, 104, 143, 0.15);

  --box-shadow-primary-dark-theme: rgba(35, 21, 58, 0.42);

  --main-active-primary: #FC4374;
  --main-active-secondary: #FF93AF;
  --main-active-tertiary: #FFEDF2;
  --main-active-quaternary: #FFB8CA;

  --main-active-tertiary-dark-theme: rgba(255, 237, 242, 0.17);

  --second-active-primary: #4681F2;
  --second-active-secondary: #6E88E2;
  --second-active-tertiary: #1B8BF3;
  --second-active-quaternary: #A8DEF5;

  --tag-color-primary: #ECF6FF;
  --tag-color-secondary: #8AA8C2;

  --ai-neutral-primary: #776990;
  --ai-neutral-secondary: rgba(255, 255, 255, 0.04);
  --ai-color-primary: #6A43AB;
  --ai-color-secondary: #ECE4F7;
  --ai-color-accent-primary: #7D46C2;
  --ai-color-accent-secondary: #B146C2;
  --ai-color-accent-tertiary: #5F46C2;

  --ai-color-secondary-dark-theme: rgba(106, 67, 171, 0.3);

  --auth-neutral-primary: #8A8395;
  --auth-neutral-secondary: #9890A6;
  --auth-color-primary: #246AF1;
  --auth-inactive-color: #F8FAFB;
  --auth-error-color: #FFEFF3;

  --auth-inactive-color-dark-theme: #2E1F47;
  --auth-error-color-dark-theme: rgba(252, 67, 116, 0.09);

  --swiper-more-icon-fill: #FFD7E1;
  --swiper-more-icon-stroke: #F59BB1;

  --tags-list-divider-color-primary: #FFB8CA;
  --tags-list-divider-color-secondary: #FF97D6;
  --tags-list-divider-color-tertiary: #E27CF2;
  --tags-list-color-primary: #FF93AF;
  --tags-list-color-secondary: #F57A99;
  --tags-list-color-tertiary: #F16287;
  --tags-list-color-quaternary: #F162B8;
  --tags-list-color-quinary: #DC3E9D;
  --tags-list-color-senary: #C11CA7;
  --tags-list-color-septenary: #C64CDA;
  --tags-list-color-octonary: #B236C6;
  --tags-list-color-nonary: #7219B7;

  --catalog-block-bg-color: #F8FBFF;
  --catalog-block-bg-color-dark-theme: #32214e;

  --paywall-bg-color: #F3F3FF;
  --paywall-text-color: #BCB6C8;

  --pagination-text-color: #939197;
  --pagination-text-color-dark-theme: #877D99;
  --pagination-neutral-primary: #54456D;
  --pagination-box-shadow: rgba(26, 14, 47, 0.24);

  --comments-neutral-primary: #F3F5F8;
  --comments-neutral-secondary: #BEBECE;
  --comments-color-primary: #E8F0FF;

  --creator-neutral-primary: #6B6F88;
  --creator-color-primary: #FFCCD9;

  --ai-card-color-primary: rgba(194, 70, 107, 0.2);
  --ai-card-color-secondary: rgba(70, 105, 194, 0.2);
  --ai-card-color-tertiary: rgba(177, 70, 194, 0.15);
  --ai-card-color-quaternary: rgba(70, 194, 135, 0.2);
  --ai-card-color-quinary: rgba(194, 70, 70, 0.2);
}
