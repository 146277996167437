.comment {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  padding: 4px 20px;
}

.comment_nested {
  width: calc(100% + 40px);
  padding-left: 72px;
  margin-left: -20px;
}

.comment__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 12px;
}

.comment__wrapper_nested {
  grid-template-columns: 30px 1fr;
  gap: 10px;
}

.comment__avatar-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.comment__avatar {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.comment__avatar_size_small {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.comment__dot {
  width: 6px;
  height: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--tag-color-secondary);
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
}

.comment__dot_type_popup {
  bottom: 10px;
}

.comment__dot_type_popup.comment__dot_size_small {
  bottom: 8px;
}

.comment__dot-line {
  width: 1px;
  height: calc(100% - 30px);
  background: var(--tag-color-secondary);
  position: absolute;
  bottom: 30px;
  z-index: 0;
}

.comment__dot-line_type_popup {
  bottom: 10px;
}

.comment__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.comment__heading {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.comment__title {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
}

.comment__date {
  color: var(--neutral-active-theme);
  text-align: right;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  opacity: 0.6;
}

.comment__text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 2px;
}

.comment__text_type_deleted {
  font-style: italic;
  font-size: 12px;
  opacity: 0.5;
}

.comment__text_type_color {
  color: var(--second-active-primary);
  line-height: 1.14;
}

.comment__form {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 12px;
  margin-top: 10px;
  position: relative;
  z-index: 0;
}

.comment__border {
  width: 300%;
  height: 1px;
  background: var(--neutral-bg-opacity-primary);
  position: absolute;
  bottom: 0;
  left: -200px;
  z-index: 10;
}

.comment__btns {
  display: flex;
  align-items: center;
  margin: 4px 0 0 -8px;
}

.comment__btns_hidden {
  opacity: 0;
  visibility: hidden;
}

.comment__btns_size_small {
  margin: 4px 0 0 -6px;
}

.comment__button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: var(--website-background-theme);
  box-sizing: border-box;
  padding: 6px 8px;

  color: var(--tag-color-secondary);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}

.comment__button_size_small {
  font-size: 10px;
  line-height: 1.2;
  padding: 4px 6px;
}

.comment__button_type_remove {
  color: var(--main-active-secondary);
}

@media screen and (max-width: 780px) {
  .comment_nested.comment_type_popup {
    width: 100%;
    margin-left: 0;
  }

  .comment__form {
    padding-bottom: 0;
    margin-top: 0;
  }
}
