.tags-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tags-list__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.tags-list__line-box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
}

.tags-list__title-box {
  width: 100%;
  max-width: 68px;
  max-height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(
    138deg,
    var(--tags-list-color-quaternary) 12.23%,
    var(--tags-list-color-octonary) 84.96%
  );
  box-sizing: border-box;
  padding: 20px 30px;
  margin-top: -10px;
  margin-bottom: -8px;
}

.tags-list__title {
  color: var(--neutral-contrast-active-theme);
  text-align: center;
  font-family: Oswald;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.08;
}

.tags-list__line {
  width: 100%;
  display: flex;
  gap: 6px;
}

.tags-list__tag-box {
  display: flex;
  align-items: center;
  gap: 6px;
}

.tags-list__tag-box::after {
  content: '/';
  color: var(--divider-color);
  text-align: center;
  font-size: 46px;
  font-weight: 100;
  line-height: 1.08;
}

.tags-list__tag-box:last-child::after {
  display: none;
}

.tags-list__tag-link {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.tags-list__tag {
  color: var(--neutral-active-theme);
  font-size: 46px;
  font-weight: 500;
  line-height: 1.08;
}

.tags-list__tag-count {
  color: var(--second-active-secondary);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.28;
}

.tags-list__arrow-box {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--main-active-tertiary-theme);
  box-sizing: border-box;
  padding: 21px;
  margin-top: -4px;
  margin-bottom: -6px;
  margin-left: 16px;
}

.tags-list__arrow {
  width: 18px;
  height: 15px;
}

.tags-list__arrow-fill {
  fill: var(--main-active-primary);
}

@media screen and (max-width: 1100px) {
  .tags-list {
    gap: 20px;
  }

  .tags-list__content {
    gap: 20px;
  }

  .tags-list__line-box {
    gap: 20px;
  }

  .tags-list__title-box {
    max-width: 48px;
    max-height: 48px;
    padding: 12px 20px;
    margin-top: -6px;
    margin-bottom: -4px;
  }

  .tags-list__title {
    font-size: 20px;
  }

  .tags-list__tag-box::after {
    font-size: 32px;
    line-height: 1.2;
  }

  .tags-list__tag {
    font-size: 32px;
    line-height: 1.2;
  }

  .tags-list__tag-count {
    font-size: 12px;
    line-height: 1.2;
  }

  .tags-list__arrow-box {
    width: 48px;
    height: 48px;
    padding: 16px;
    margin-top: -4px;
    margin-bottom: -6px;
    margin-left: 12px;
  }
}

@media screen and (max-width: 780px) {
  .tags-list {
    gap: 10px;
  }

  .tags-list__content {
    gap: 12px;
  }

  .tags-list__line-box {
    gap: 12px;
  }

  .tags-list__title-box {
    max-width: 34px;
    max-height: 34px;
    padding: 8px 12px;
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .tags-list__title {
    font-size: 16px;
    line-height: 1.12;
  }

  .tags-list__tag-box::after {
    color: var(--tags-list-divider-color-primary);
    font-size: 20px;
    line-height: 1.3;
  }

  .tags-list__tag {
    font-size: 20px;
    line-height: 1.3;
  }

  .tags-list__tag-count {
    font-size: 10px;
  }

  .tags-list__arrow-box {
    width: 34px;
    height: 34px;
    padding: 12px;
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .tags-list__arrow {
    width: 11px;
    height: 9px;
  }
}
