.search-nav-bar {
  width: 100%;
  display: flex;
}

.search-nav-bar__button {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  border-radius: 600px;
  padding: 6px 14px;
}

@media screen and (max-width: 780px) {
  .search-nav-bar {
    width: calc(100% + 64px);
    overflow: scroll;
    box-sizing: border-box;
    padding: 0 26px;
  }

  .search-nav-bar_type_page {
    width: 100%;
    padding: 0 16px;
  }

  .search-nav-bar::-webkit-scrollbar {
    display: none;
  }
}
