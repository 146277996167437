.reader-content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 780px) {
  .reader-content {
    gap: 14px;
  }
}
