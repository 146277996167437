.popular-search-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popular-search-list__container {
  width: 100%;
  display: flex;
  align-items: center;
}

.popular-search-list__container_type_tag {
  gap: 4px;
}

.popular-search-list__container_type_story {
  gap: 12px;
}

@media screen and (max-width: 780px) {
  .popular-search-list_type_tag {
    gap: 12px;
  }
}

@media screen and (max-width: 540px) {
  .popular-search-list__container_type_story {
    flex-direction: column;
  }
}
