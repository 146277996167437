.catalog-popup-block {
  width: calc(100% - 52px);
  display: grid;
  grid-template-columns: 104px 1fr;
  border-top: 1px solid var(--neutral-line-primary-opacity);
  box-sizing: border-box;
  padding: 24px 0;
  margin: 0 26px;
}

.catalog-popup-block:first-of-type {
  border: none;
}

.catalog-popup-block:last-of-type {
  width: 100%;
  border: none;
  background-color: var(--catalog-block-bg-color-theme);
  padding: 24px 26px;
  margin: 0;
}

.catalog-popup-block__title {
  color: var(--neutral-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
}

.catalog-popup-block__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.catalog-popup-block__items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minMax(0, 1fr));
  grid-auto-columns: minMax(0, 1fr);
  grid-template-rows: repeat(var(--grid-rows), 1fr);
  grid-auto-flow: column;
  align-items: end;
  gap: 12px 30px;
}

.catalog-popup-block__items_type_stories {
  grid-template-rows: repeat(2, 1fr);
  gap: 16px 30px;
}

.catalog-popup-block__link {
  display: flex;
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.catalog-popup-block__link_type_all {
  color: var(--second-active-primary);
}

.catalog-popup-block__link_type_stories {
  padding-bottom: 7px;
}

.catalog-popup-block__empty {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
}

@media screen and (max-width: 780px) {
  .catalog-popup-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-top: none;
    padding: 0;
    margin: 0;
  }

  .catalog-popup-block:last-of-type {
    background-color: unset;
    padding: 0;
  }

  .catalog-popup-block__content {
    max-height: 56vh;
    align-items: center;
  }

  .catalog-popup-block__items {
    height: 100%;
    align-items: start;
    gap: 12px 24px;
  }

  .catalog-popup-block__items_type_stories {
    grid-template-columns: repeat(var(--grid-columns), minMax(0, 1fr));
    grid-template-rows: repeat(var(--grid-rows), 1fr);
    gap: 16px 30px;
  }

  .catalog-popup-block__link_type_all {
    font-weight: 700;
  }
}
