.content-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-block__line {
  width: 100%;
  margin: 20px 0;
}

.content-block:first-child .content-block__line {
  display: none;
}

.content-block .bold {
  font-weight: 700;
}

.content-block .italic {
  font-style: italic;
}

.content-block .underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.content-block .strikethrough {
  text-decoration: line-through;
}

@media screen and (max-width: 780px) {
  .content-block {
    gap: 14px;
  }

  .content-block__line {
    margin: 16px 0;
  }
}
