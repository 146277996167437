.popular-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.popular-search__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-top: 1px solid var(--neutral-line-primary-opacity);
  padding-top: 16px;
}

.popular-search__card:first-child {
  border-top: none;
  padding-top: 0;
}

.popular-search__title {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.67;
}

@media screen and (max-width: 780px) {
  .popular-search {
    gap: 24px;
  }

  .popular-search__card {
    padding-top: 24px;
  }

  .popular-search__title {
    font-size: 12px;
  }
}
