.banners-list {
  width: 100vw;
  max-width: var(--max-width);
}

.banners-list__swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.banners-list__progress-wrapper {
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 28px;
  right: 38px;
  box-sizing: border-box;
  z-index: 10;
}

.banners-list__slide-progress {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banners-list__logo {
  width: 54px;
  height: 36px;
  position: absolute;
  z-index: 1;
}

.banners-list__logo-fill {
  fill: var(--neutral-active);
}

.banners-list__autoplay-progress {
  --progress: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  stroke-width: 4px;
  stroke: var(--neutral-active);
  fill: none;
  stroke-dashoffset: calc(288px * (1 - var(--progress)));
  stroke-dasharray: 288;
  transform: rotate(-90deg);
  z-index: 10;
}

@media screen and (max-width: 900px) {
  .banners-list__progress-wrapper {
    display: none;
  }
}
