.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.profile__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-top: 1px solid var(--neutral-border-primary);
  padding-top: 30px;
}

.profile__block:first-child {
  border-top: none;
  padding-top: 0;
}

.profile__block-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile__title {
  color: var(--neutral-active-theme);
  font-size: 24px;
  font-weight: 200;
  line-height: 1.1;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.profile__text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

.profile__text_type_color {
  color: var(--auth-color-primary);
  font-weight: 500;
}

.profile__btn-container {
  max-width: 252px;
}

@media screen and (max-width: 1440px) {
  .profile {
    max-width: var(--desk-content-max-width);
  }
}

@media screen and (max-width: 780px) {
  .profile {
    gap: 30px;
  }

  .profile__block {
    gap: 12px;
    padding-top: 20px;
  }

  .profile__block:first-child {
    border-top: 1px solid var(--neutral-border-primary);
    padding-top: 20px;
  }

  .profile__block-content {
    gap: 12px;
  }

  .profile__btn-container {
    max-width: 100%;
  }
}
