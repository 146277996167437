.search-dropdown-category__text-box {
  display: flex;
  align-items: center;
  border-radius: 600px;
  border: 1px solid var(--main-active-secondary);
  box-sizing: border-box;
  padding: 5px 9px;
}

.search-dropdown-category__text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}
