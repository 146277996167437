.dialogue-text-element {
  width: 100%;
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  align-items: center;
  gap: 16px;
}

.dialogue-text-element__icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--neutral-active-theme);
  opacity: 0;
  box-sizing: border-box;
}

.dialogue-text-element__icon-container_type_right {
  transform: scaleX(-1);
}

.dialogue-text-element__icon-container_visible {
  opacity: 1;
}

.dialogue-text-element__icon {
  width: 16px;
  height: 32px;
}

.dialogue-text-element__icon-fill {
  fill: var(--neutral-active-theme);
}

.dialogue-text-element__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: var(--neutral-active-theme);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

@media screen and (max-width: 780px) {
  .dialogue-text-element {
    width: calc(100% + 32px);
    grid-template-columns: 12px 1fr 12px;
    gap: 6px;
  }

  .dialogue-text-element__icon-container {
    align-items: flex-start;
    border-right: none;
    transform: scaleX(-1);
    margin-top: -5px;
  }

  .dialogue-text-element__icon-container_type_right {
    transform: none;
  }

  .dialogue-text-element__icon {
    width: 12px;
    height: 24px;
  }

  .dialogue-text-element__text {
    font-size: 14px;
    line-height: 1.428;
  }
}
