.pagination {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 110px;
  background: var(--website-background);
  box-shadow: 0px 14px 24px 0px var(--box-shadow-primary);
  box-sizing: border-box;
  padding: 4px;
}

.pagination_theme_dark {
  background: var(--pagination-neutral-primary);
  box-shadow: 0px 14px 24px 0px var(--pagination-box-shadow);
}

.pagination__nav-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--neutral-active);
  box-sizing: border-box;
  padding: 12px;
  transition: all 0.2s ease-in-out;
}

.pagination__nav-btn_disabled {
  opacity: 0.2;
  pointer-events: none;
  user-select: none;
}

.pagination__arrow {
  width: 100%;
  height: 100%;
}

.pagination__arrow_type_prev {
  transform: rotate(-90deg);
}

.pagination__arrow_type_next {
  transform: rotate(90deg);
}

.pagination__arrow-fill {
  fill: var(--neutral-contrast-active);
}

.pagination__fraction {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--neutral-active-theme);
  font-size: 16px;
  line-height: 1.375;
}

.pagination__fraction_type_current {
  font-weight: 700;
}

.pagination__fraction_type_slash {
  color: var(--pagination-text-color-theme);
  font-weight: 200;
}

.pagination__fraction_type_total {
  color: var(--pagination-text-color-theme);
  font-weight: 400;
}

.pagination__fraction-arrow-box {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__fraction-arrow {
  width: 100%;
  height: 100%;
}

.pagination__fraction-arrow-fill {
  fill: var(--neutral-active-theme);
}
