.auth-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.auth-input__container {
  width: 100%;
  height: 48px;
  position: relative;
  background: var(--auth-inactive-color-theme);
  border: 1px solid var(--auth-inactive-color-theme);
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.auth-input__container_theme_dark {
  border: 1px solid var(--neutral-line-primary-theme);
}

.auth-input__container_invalid {
  border-color: var(--auth-error-color-theme);
  background-color: var(--auth-error-color-theme);
}

.auth-input__container_focused {
  border-color: var(--auth-color-primary);
}

.auth-input__label {
  max-width: calc(100% - 32px);
  position: absolute;
  transform-origin: top left;
  transform: translate(16px, 17px) scale(1);
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;

  color: var(--auth-neutral-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.auth-input__label_focused {
  max-width: calc(140% - 32px);
  transform: translate(16px, 8px) scale(0.7);
  pointer-events: auto;
  user-select: none;
}

.auth-input__input {
  width: 100%;
  height: 100%;
  color: var(--neutral-active-theme);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 24px 16px 8px;
  position: relative;
  z-index: 1;
}

.auth-input__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 500px var(--auth-inactive-color-theme) inset !important;
  -webkit-text-fill-color: var(--neutral-active-theme) !important;
  border: none;
  background-color: var(--auth-inactive-color-theme) !important;
}

.auth-input__input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 500px var(--auth-inactive-color-theme) inset !important;
  -webkit-text-fill-color: var(--neutral-active-theme) !important;
  background-color: var(--auth-inactive-color-theme) !important;
}

.auth-input__input_with-icon {
  padding-right: 48px;
}

.auth-input__input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: transparent;
  background: none;
  z-index: 10;
}

.auth-input__input[type='date']::-webkit-datetime-edit {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.auth-input__input[type='date']::-webkit-date-and-time-value {
  text-align: left;
}

.auth-input__container_focused
  .auth-input__input[type='date']::-webkit-datetime-edit {
  opacity: 1;
}

.auth-input__input_filled[type='date']::-webkit-datetime-edit {
  opacity: 1;
}

.auth-input__icon-box {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 0;
}

.auth-input__icon-box_type_btn {
  z-index: 2;
}

.auth-input__icon-wrapper {
  width: 100%;
  height: 100%;
}

.auth-input__icon {
  width: 100%;
  height: 100%;
}

.auth-input__icon-fill {
  fill: var(--neutral-active-theme);
}
