.content-types-nav {
  width: 100%;
  overflow: scroll;
  box-sizing: border-box;
}

.content-types-nav::-webkit-scrollbar {
  display: none;
}

.content-types-nav__list {
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.content-types-nav__item:has(.content-types-nav__link_active) {
  user-select: none;
  pointer-events: none;
}

.content-types-nav__link {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  background-color: var(--neutral-bg-opacity-primary);
  box-sizing: border-box;
  padding: 6px 14px;

  color: var(--neutral-active);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  white-space: nowrap;

  transition: all 0.3s ease-in-out;
}

.content-types-nav__link_active {
  background-color: var(--neutral-active);
  color: var(--neutral-contrast-active);
  user-select: none;
  pointer-events: none;
}

@media screen and (max-width: 780px) {
  .content-types-nav {
    width: 100vw;
    padding: 0 16px;
    margin-left: -16px;
  }

  .content-types-nav__link {
    padding: 6px 12px;
  }
}
