.main-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 0 106px;
  overflow: hidden;
}

.main-page__feed {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  box-sizing: border-box;
  padding: 0 64px;
}

@media screen and (max-width: 1440px) {
  .main-page__feed {
    max-width: calc(var(--container-max-width) + 32px * 2);
    padding: 0 32px;
  }
}

@media screen and (max-width: 1100px) {
  .main-page {
    padding: 28px 0 80px;
  }

  .main-page__feed {
    gap: 80px;
  }
}

@media screen and (max-width: 780px) {
  .main-page {
    padding: 4px 0 44px;
  }

  .main-page__feed {
    gap: 40px;
    padding: 0 16px;
  }
}
