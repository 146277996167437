.app {
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--website-background-theme);
  overflow: hidden;
}

.app__container {
  width: 100%;
  max-width: var(--max-width);
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-top: 92px;
}

@media screen and (max-width: 1100px) {
  .app__container {
    padding-top: 82px;
  }
}

@media screen and (max-width: 780px) {
  .app__container {
    padding-top: 68px;
  }
}
