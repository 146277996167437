.history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.history__content {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .history {
    max-width: var(--desk-content-max-width);
  }
}
