.desk-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.desk-grid__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desk-grid__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.desk-grid__text {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
}

.desk-grid__more-button {
  width: fit-content;
  color: transparent;
  transition: opacity 0.2s ease-in-out;
  cursor: default;
}

@media screen and (max-width: 1100px) {
  .desk-grid__list {
    grid-template-columns: repeat(auto-fill, minmax(174px, 1fr));
    gap: 12px;
  }
}

@media screen and (max-width: 780px) {
  .desk-grid__list {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    gap: 10px;
  }
}
