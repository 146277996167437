.password-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.password-form__button {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background-color: var(--website-background-theme);
}

.password-form__button-text {
  color: var(--auth-color-primary);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

@media screen and (max-width: 780px) {
  .password-form__button {
    width: fit-content;
    height: 16px;
    margin: 20px auto 0;
  }
}
