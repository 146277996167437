.search-dropdown-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--second-active-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.125;
  white-space: nowrap;
}

.search-dropdown-tag::before {
  content: '•';
  color: var(--neutral-line-primary-theme);
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
}

.search-dropdown-tag:first-child::before {
  display: none;
}

@media screen and (max-width: 780px) {
  .search-dropdown-tag {
    font-size: 14px;
    line-height: 1.14;
  }
}
