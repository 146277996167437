.search-dropdown {
  width: 72%;
  max-width: 890px;
  min-width: 518px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.search-dropdown__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-dropdown__popup-content {
  width: 56vw;
  max-width: 802px;
  min-width: 428px;
  min-height: 312px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  box-sizing: border-box;
  padding: 16px 26px;
}

.search-dropdown__preloader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--website-background-theme);
  z-index: 10;
}

@media screen and (max-width: 780px) {
  .search-dropdown__popup-content {
    width: 100%;
    max-width: unset;
    min-width: unset;
    min-height: unset;
    padding: 16px 32px 24px;
  }

  .search-dropdown__preloader {
    position: relative;
    margin-top: 32px;
  }
}
