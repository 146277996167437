.ai-content-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  padding-bottom: 60px;
}

.ai-content-block__line {
  width: 100%;
  margin: 20px 0;
}

.ai-content-block__line:first-child {
  display: none;
}

@media screen and (max-width: 780px) {
  .ai-content-block {
    gap: 14px;
    padding-bottom: 40px;
  }

  .ai-content-block__line {
    margin: 16px 0;
  }
}
