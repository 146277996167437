.banner {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--banner-color);
  box-sizing: border-box;
  padding: 0 32px 0 590px;
}

.banner__image-link {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.banner__image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center left;
}

.banner__skeleton-loader {
  background-color: var(--banner-color);
}

.banner__content {
  max-width: 668px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.banner__subtitle {
  color: var(--second-active-primary);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
}

.banner__title {
  font-size: 64px;
  font-weight: 500;
  line-height: 1.06;
  padding-top: 16px;
}

.banner__info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 42px;
}

.banner__btns-block {
  display: flex;
  align-items: center;
}

.banner__link {
  width: fit-content;
}

.banner__link-btn {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  background: var(--main-active-primary);
  box-sizing: border-box;
  padding: 14px 40px;

  color: var(--neutral-contrast-active);
  font-size: 26px;
  font-weight: 500;
  line-height: 1.23;
}

.banner__save-btn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--main-active-primary);
  background: var(--neutral-contrast-active);
  box-sizing: border-box;
  transition: background 0.3s ease-in-out;
}

.banner__save-btn_active {
  background: var(--main-active-primary);
}

.banner__save-icon {
  width: 20px;
  height: 25px;
}

.banner__save-icon-fill {
  fill: var(--main-active-primary);
  transition: fill 0.3s ease-in-out;
}

.banner__save-icon-fill_active {
  fill: var(--neutral-contrast-active);
}

@media screen and (max-width: 1100px) {
  .banner {
    padding: 0 32px 0 432px;
  }

  .banner__content {
    max-width: 500px;
  }

  .banner__title {
    font-size: 48px;
  }

  .banner__info {
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
  }

  .banner__info-block {
    margin-left: 16px;
  }
}

@media screen and (max-width: 780px) {
  .banner {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 16px 16px 20px;
  }

  .banner__image-link {
    position: relative;
  }

  .banner__image {
    height: 430px;
    border-radius: 8px;
  }

  .banner__content {
    max-width: 100%;
  }

  .banner__subtitle {
    font-size: 14px;
    line-height: 1.29;
  }

  .banner__title {
    font-size: 30px;
    line-height: 1.13;
    padding-top: 10px;
  }

  .banner__info {
    max-width: 334px;
    justify-content: space-between;
    gap: 16px 20px;
  }

  .banner__link-btn {
    padding: 12px 22px;
    font-size: 16px;
    line-height: 1.25;
  }

  .banner__save-btn {
    width: 44px;
    height: 44px;
  }

  .banner__save-icon {
    width: 14px;
    height: 17px;
  }
}

@media screen and (max-width: 580px) {
  .banner__image {
    object-position: center;
  }
}
