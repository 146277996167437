.reader {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
  padding: 28px 64px 10px;
}

@media screen and (max-width: 1440px) {
  .reader {
    max-width: calc(var(--reader-max-width) + 32px * 2);
    padding: 28px 32px 10px;
  }
}

@media screen and (max-width: 780px) {
  .reader {
    align-items: center;
    padding: 4px 16px 0;
    gap: 30px;
  }
}
