.profile-form-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.profile-form-block__content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 192px;
  align-items: center;
  gap: 8px;
}

.profile-form-block__content_type_avatar {
  grid-template-columns: 100px 1fr 192px;
}

.profile-form-block__avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  background-color: var(--neutral-bg-primary);
}

.profile-form-block__avatar-btns {
  width: 100%;
  max-width: 420px;
  display: flex;
  gap: 8px;
}

.profile-form-block__custom-file-btn {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 80px;
  border: 1px solid var(--neutral-active-theme);
  color: var(--neutral-active-theme);
  box-sizing: border-box;
  cursor: pointer;

  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
}

.profile-form-block__invisible-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

@media screen and (max-width: 1100px) {
  .profile-form-block__avatar-btns {
    max-width: 144px;
    flex-direction: column;
    gap: 4px;
  }
}

@media screen and (max-width: 780px) {
  .profile-form-block__content {
    grid-template-columns: 1fr 120px;
    gap: 4px;
  }

  .profile-form-block__content_type_avatar {
    grid-template-columns: 80px 1fr 120px;
  }

  .profile-form-block__avatar {
    width: 80px;
    height: 80px;
  }

  .profile-form-block__custom-file-btn {
    height: 40px;
    font-size: 12px;
  }
}
