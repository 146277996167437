.promo {
  width: 100vw;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: var(--block-background-tertiary);
  box-sizing: border-box;
  padding: 80px 80px 80px 64px;
}

.promo__container {
  width: 100%;
  display: flex;
  gap: 76px;
}

.promo__text-block {
  max-width: 342px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.promo__title {
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.promo__cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.promo__card {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.promo__card-img {
  width: 62px;
}

.promo__text-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.promo__card-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.12;
}

.promo__card-text {
  color: var(--ai-neutral-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
}

.promo__form-block {
  width: 100%;
  display: flex;
  position: relative;
}

.promo__form-container {
  width: 100%;
  height: 100%;
  min-height: 504px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: var(--website-background);
  box-sizing: border-box;
  padding: 40px 122px 30px 80px;

  box-shadow: 5px 5px var(--ai-color-secondary),
    10px 10px var(--ai-color-secondary), 15px 15px var(--ai-color-secondary),
    20px 20px var(--ai-color-secondary), 25px 25px var(--ai-color-secondary),
    30px 30px var(--ai-color-secondary), 35px 35px var(--ai-color-secondary),
    40px 40px var(--ai-color-secondary), 45px 45px var(--ai-color-secondary),
    50px 50px var(--ai-color-secondary), 55px 55px var(--ai-color-secondary),
    60px 60px var(--ai-color-secondary), 65px 65px var(--ai-color-secondary),
    70px 70px var(--ai-color-secondary), 75px 75px var(--ai-color-secondary),
    80px 80px var(--ai-color-secondary), 85px 85px var(--ai-color-secondary),
    90px 90px var(--ai-color-secondary), 95px 95px var(--ai-color-secondary),
    100px 100px var(--ai-color-secondary), 105px 105px var(--ai-color-secondary);
}

@media screen and (max-width: 1440px) {
  .promo {
    padding: 80px 32px;
  }

  .promo__container {
    max-width: var(--container-max-width);
  }

  .promo__form-container {
    padding: 40px 80px 30px;
  }
}

@media screen and (max-width: 1100px) {
  .promo {
    padding: 60px 32px;
  }

  .promo__container {
    flex-direction: column;
    gap: 30px;
  }

  .promo__text-block {
    max-width: 100%;
    gap: 30px;
  }

  .promo__title {
    width: 100%;
    font-size: 48px;
    letter-spacing: -0.96px;
  }

  .promo__cards {
    gap: 20px;
  }

  .promo__form-container {
    padding: 40px 32px 30px;
    box-shadow: 5px 5px var(--ai-color-secondary),
      10px 10px var(--ai-color-secondary), 15px 15px var(--ai-color-secondary),
      20px 20px var(--ai-color-secondary), 25px 25px var(--ai-color-secondary),
      30px 30px var(--ai-color-secondary), 35px 35px var(--ai-color-secondary),
      40px 40px var(--ai-color-secondary), 45px 45px var(--ai-color-secondary),
      50px 50px var(--ai-color-secondary), 55px 55px var(--ai-color-secondary),
      60px 60px var(--ai-color-secondary), 65px 65px var(--ai-color-secondary);
  }
}

@media screen and (max-width: 780px) {
  .promo {
    padding: 30px 16px;
  }

  .promo__container {
    gap: 20px;
  }

  .promo__text-block {
    gap: 20px;
  }

  .promo__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .promo__form-container {
    min-height: 416px;
    padding: 20px;
    box-shadow: 5px 5px var(--ai-color-secondary),
      10px 10px var(--ai-color-secondary), 15px 15px var(--ai-color-secondary),
      20px 20px var(--ai-color-secondary), 25px 25px var(--ai-color-secondary),
      30px 30px var(--ai-color-secondary), 35px 35px var(--ai-color-secondary);
  }
}
