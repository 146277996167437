.desktop-popup {
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  visibility: hidden;
  transition: all 0.6s ease-in-out;
}

.desktop-popup_visible {
  visibility: visible;
}

.desktop-popup__container {
  width: 90%;
  max-width: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 14px 24px 0px var(--box-shadow-primary-theme);
  background: var(--website-background-theme);
  z-index: 1000;
}

.desktop-popup__container_type_full-screen {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
}

.desktop-popup__close-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  box-sizing: border-box;
  padding: 4px;
}

.desktop-popup__close-icon {
  width: 100%;
  height: 100%;
}

.desktop-popup__close-icon-fill {
  fill: var(--auth-neutral-secondary);
}

.desktop-popup__overlay {
  width: 100%;
  height: 100%;
  background: var(--popup-background);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.desktop-popup__overlay_type_modal {
  backdrop-filter: blur(8px);
}
