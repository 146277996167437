.search-results {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.search-results__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.search-results__link {
  color: var(--second-active-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125;
}

.search-results__no-results {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  text-align: center;
}

@media screen and (max-width: 780px) {
  .search-results__link {
    font-size: 14px;
    line-height: 1.14;
  }
}
