.profile-form-buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 144px 48px;
  align-items: center;
}

.profile-form-buttons__close-btn {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--neutral-bg-opacity-primary);
}

.profile-form-buttons__close-icon {
  width: 14px;
  height: 14px;
}

.profile-form-buttons__close-icon-fill {
  fill: var(--neutral-active-theme);
}

@media screen and (max-width: 780px) {
  .profile-form-buttons {
    grid-template-columns: 80px 40px;
  }

  .profile-form-buttons__close-btn {
    width: 40px;
    height: 40px;
  }

  .profile-form-buttons__close-icon {
    width: 12px;
    height: 12px;
  }
}
