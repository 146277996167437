.desk-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 16px;
  border: 1px solid var(--neutral-border-primary);
  box-sizing: border-box;
  padding: 12px;
}

.desk-nav__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
}

.desk-nav__list_border_top {
  border-top: 1px solid var(--neutral-line-primary);
  padding-top: 6px;
}

.desk-nav__item {
  width: 100%;
  border-top: 1px solid var(--neutral-line-primary-opacity);
  box-sizing: border-box;
  padding-top: 6px;
  transition: border-color 0.3s ease-in-out;
}

.desk-nav__item:has(.desk-nav__link_active) {
  border-top: 1px solid var(--website-background);
  user-select: none;
  pointer-events: none;
}

.desk-nav__item:has(.desk-nav__link_active) + .desk-nav__item {
  border-top: 1px solid var(--website-background);
}

.desk-nav__item:first-child {
  border-top: none;
  padding-top: 0;
}

.desk-nav__link {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background-color: var(--website-background);
  box-sizing: border-box;
  padding: 10px 12px;
  transition: background-color 0.3s ease-in-out;
}

.desk-nav__link_active {
  background-color: var(--neutral-active);
  user-select: none;
  pointer-events: none;
}

.desk-nav__link-icon {
  width: 100%;
  max-width: 20px;
  height: 20px;
}

.desk-nav__link-icon-stroke {
  stroke: var(--neutral-active);
  transition: stroke 0.3s ease-in-out;
}

.desk-nav__link_active .desk-nav__link-icon-stroke {
  stroke: var(--neutral-contrast-active);
}

.desk-nav__link-label {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  transition: color 0.3s ease-in-out;
}

.desk-nav__link-label_type_number {
  text-align: right;
  opacity: 0.5;
}

.desk-nav__link_active .desk-nav__link-label {
  color: var(--neutral-contrast-active);
}

.desk-nav__link-arrow {
  width: 100%;
  max-width: 6px;
  height: 12px;
}

.desk-nav__link-arrow-fill {
  fill: var(--website-background);
}

@media screen and (max-width: 1100px) {
  .desk-nav {
    padding: 8px;
  }

  .desk-nav__link {
    gap: 8px;
  }

  .desk-nav__link-icon {
    max-width: 16px;
    height: 16px;
  }

  .desk-nav__link-label {
    font-size: 12px;
    line-height: 1.17;
  }
}
