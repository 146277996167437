.preferences-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.preferences-list_align_left {
  justify-content: flex-start;
}

.preferences-list__btn {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  border: 1px solid var(--main-active-secondary);
  box-sizing: border-box;
  padding: 13px 27px;

  color: var(--neutral-active-theme);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  white-space: nowrap;
}

@media screen and (max-width: 780px) {
  .preferences-list__btn {
    font-size: 16px;
    line-height: 1.13;
    padding: 5px 9px;
  }
}
