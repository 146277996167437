.reader-heading {
  width: 100%;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: repeat(2, minmax(min-content, max-content));
  grid-template-areas:
    'image title'
    'image info';
  gap: 26px 30px;
}

.reader-heading_size_small {
  grid-template-columns: 54px 1fr 42px;
  grid-template-areas:
    'image title info'
    'line line line';
  align-items: center;
  gap: 20px 16px;
}

.reader-heading_type_ai {
  align-items: end;
}

.reader-heading__image-wrapper {
  grid-area: image;
  width: 100%;
  height: 100%;
  min-height: 314px;
  display: flex;
}

.reader-heading__image-wrapper_size_small {
  min-height: 66px;
}

.reader-heading__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.reader-heading__image_size_small {
  border-radius: 6px;
}

.reader-heading__title-block {
  grid-area: title;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
}

.reader-heading__title-block_size_small {
  gap: 10px;
  margin-top: 0;
}

.reader-heading__author-link {
  width: fit-content;
}

.reader-heading__author-block {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--second-active-primary);
}

.reader-heading__logo-box {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--neutral-contrast-active);
}

.reader-heading__logo {
  width: 20px;
  height: 14px;
}

.reader-heading__logo-fill {
  fill: var(--neutral-active);
}

.reader-heading__author-avatar {
  min-width: 42px;
  max-width: 42px;
  min-height: 42px;
  max-height: 42px;
}

.reader-heading__author-text {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
}

.reader-heading__author-text_size_small {
  font-size: 12px;
  line-height: 1.17;
}

.reader-heading__title {
  color: var(--neutral-active-theme);
  font-size: 48px;
  font-weight: 300;
  line-height: 1.08;
}

.reader-heading__title_size_small {
  font-size: 22px;
  line-height: 1.18;
}

.reader-heading__info-block {
  grid-area: info;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.reader-heading__info-block_type_ai {
  align-self: start;
}

.reader-heading__info-block_size_small {
  align-self: end;
  margin-bottom: 6px;
}

.reader-heading__info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.reader-heading__save-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--main-active-primary);
  background-color: var(--neutral-contrast-active-theme);
  box-sizing: border-box;
  transition: background-color 0.3s ease-in-out;
}

.reader-heading__save-btn_size_small {
  width: 30px;
  height: 30px;
  border: 1.4px solid var(--main-active-primary);
}

.reader-heading__save-btn_active {
  background-color: var(--main-active-primary);
}

.reader-heading__save-icon {
  width: 13px;
  height: 16px;
}

.reader-heading__save-icon_size_small {
  width: 10px;
  height: 12px;
}

.reader-heading__save-icon-fill {
  fill: var(--main-active-primary);
  transition: fill 0.3s ease-in-out;
}

.reader-heading__save-icon-fill_active {
  fill: var(--neutral-contrast-active);
}

.reader-heading__tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.reader-heading__tag {
  max-width: 100%;
  display: flex;
  border-radius: 600px;
  background: var(--tag-color-primary);
  overflow: hidden;
}

.reader-heading__tag-link {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 12px;
}

.reader-heading__tag-text {
  color: var(--neutral-active);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reader-heading__tag-text_type_color {
  color: var(--tag-color-secondary);
}

.reader-heading__divider {
  grid-area: line;
}

@media screen and (max-width: 780px) {
  .reader-heading {
    width: calc(100% + 16px * 2);
    grid-template-columns: 1fr 116px;
    grid-template-areas:
      'title image'
      'info info';
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid var(--neutral-line-primary-theme);
    box-sizing: border-box;
    padding: 0 16px 12px;
  }

  .reader-heading_size_small {
    width: 100%;
    grid-template-columns: 54px 1fr 30px;
    grid-template-rows: 1fr;
    grid-template-areas: 'image title info';
    gap: 16px;
    padding: 0 0 16px;
  }

  .reader-heading__image-wrapper {
    min-height: 140px;
  }

  .reader-heading__image-wrapper_size_small {
    min-height: 66px;
  }

  .reader-heading__title-block {
    gap: 12px;
    margin-top: 0;
  }

  .reader-heading__title-block_size_small {
    gap: 8px;
  }

  .reader-heading__author-block {
    gap: 10px;
  }

  .reader-heading__author-avatar {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
  }

  .reader-heading__author-text {
    font-size: 12px;
    line-height: 1.17;
  }

  .reader-heading__title {
    font-size: 26px;
    line-height: 1.15;
  }

  .reader-heading__title_size_small {
    font-size: 18px;
    line-height: 1.22;
  }

  .reader-heading__info-block {
    gap: 16px;
    border-top: 1px solid var(--neutral-line-primary-theme);
    padding-top: 12px;
  }

  .reader-heading__info-block_type_ai {
    align-items: center;
  }

  .reader-heading__info-block_size_small {
    border-top: none;
    padding-top: 0;
  }

  .reader-heading__save-btn {
    width: 30px;
    height: 30px;
    border: 1.4px solid var(--main-active-primary);
  }

  .reader-heading__save-icon {
    width: 10px;
    height: 12px;
  }

  .reader-heading__tag-link {
    padding: 3px 8px;
  }

  .reader-heading__tag-text {
    font-size: 10px;
    line-height: 1.2;
  }

  .reader-heading__divider {
    display: none;
  }
}
