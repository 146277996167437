.avatar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral-bg-primary);
  overflow: hidden;
  z-index: 10;
}

.avatar__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
