.comment-form {
  width: 100%;
  display: flex;
  align-items: center;
}

.comment-form__input {
  width: 100%;
  height: 40px;
  background: var(--comments-neutral-primary);
  border: 1px solid var(--comments-neutral-primary);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 12px 16px;
  resize: none;
  overflow: auto;
  transition: all 0.2s ease-in-out;

  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.comment-form__input::placeholder {
  color: var(--comments-neutral-secondary);
}

.comment-form__input:focus {
  border-color: var(--auth-color-primary);
}

.comment-form__input::-webkit-scrollbar {
  display: none;
}

.comment-form__btn {
  width: 100%;
  max-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--neutral-active-theme);
  opacity: 0.5;
}

.comment-form__btn_type_close {
  background-color: var(--website-background-theme);
  opacity: 1;
}

.comment-form__btn-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-form__submit-icon {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}

.comment-form__submit-icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.comment-form__close-icon {
  width: 12px;
  height: 12px;
}

.comment-form__close-icon-fill {
  fill: var(--neutral-active-theme);
}
