.search-page {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
  padding: 28px 64px 100px;
}

.search-page__title {
  color: var(--neutral-active-theme);
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-page__navbar {
  width: 100%;
}

.search-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-page__no-results {
  color: var(--neutral-active-theme);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.14;
  text-align: center;
}

.search-page__more-button {
  width: fit-content;
  color: transparent;
  transition: opacity 0.2s ease-in-out;
  cursor: default;
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  .search-page {
    max-width: calc(var(--container-max-width) + 32px * 2);
    min-height: 100dvh;
    padding: 28px 32px 100px;
  }

  .search-page__title {
    font-size: 48px;
    letter-spacing: -0.96px;
  }
}

@media screen and (max-width: 1100px) {
  .search-page {
    gap: 30px;
    padding: 28px 32px 80px;
  }

  .search-page__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .search-page__no-results {
    font-size: 14px;
  }
}

@media screen and (max-width: 780px) {
  .search-page {
    gap: 20px;
    padding: 16px 16px 40px;
  }

  .search-page__title {
    font-size: 28px;
    letter-spacing: -0.56px;
  }

  .search-page__navbar {
    width: calc(100% + 16px * 2);
    margin-left: -16px;
  }
}
