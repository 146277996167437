.authors-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.authors-cards__heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.authors-cards__title {
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
  white-space: nowrap;
}

.authors-cards__line {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--neutral-line-primary);
  box-sizing: border-box;
  padding-left: 4px;
  margin-left: 4px;
}

.authors-cards__line-icon {
  width: 7px;
  height: 7px;
  position: absolute;
}

.authors-cards__line-icon_position_left {
  left: 0;
}

.authors-cards__line-icon_position_right {
  right: 0;
}

.authors-cards__line-icon-fill {
  fill: var(--neutral-line-primary);
}

.authors-cards__content-box {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 1100px) {
  .authors-cards {
    gap: 30px;
  }

  .authors-cards__title {
    font-size: 48px;
    letter-spacing: -0.96px;
  }
}

@media screen and (max-width: 780px) {
  .authors-cards {
    gap: 20px;
  }

  .authors-cards__heading {
    gap: 10px;
  }

  .authors-cards__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .authors-cards__content-box {
    width: calc(100% + 32px);
    overflow: scroll;
    margin-left: -16px;
  }

  .authors-cards__content-box::-webkit-scrollbar {
    display: none;
  }

  .authors-cards__list {
    display: grid;
    grid-template-columns: 220px;
    grid-auto-columns: 220px;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 16px 32px;
    box-sizing: border-box;
    padding: 0 16px;
  }
}
