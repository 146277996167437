.author-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.author-info__box {
  display: flex;
  align-items: center;
  gap: 4px;
}

.author-info__icon-wrapper {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.author-info__icon-wrapper_size_big {
  width: 22px;
  height: 22px;
}

.author-info__gems-icon {
  width: 14px;
  height: 14px;
}

.author-info__icon-wrapper_size_big .author-info__gems-icon {
  width: 20px;
  height: 20px;
}

.author-info__gems-icon-stroke {
  stroke: var(--main-active-primary);
}

.author-info__eye-icon {
  width: 100%;
  height: 100%;
}

.author-info__heart-icon {
  width: 14px;
  height: 12px;
}

.author-info__icon-wrapper_size_big .author-info__heart-icon {
  width: 20px;
  height: 18px;
}

.author-info__icon-fill {
  fill: var(--neutral-active-theme);
}

.author-info__text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
}

.author-info__text_size_medium {
  font-size: 16px;
  line-height: 1.12;
}

.author-info__text_size_big {
  font-size: 18px;
  line-height: 1.22;
}

.author-info__text_weight_thin {
  font-weight: 100;
}

@media screen and (max-width: 1100px) {
  .author-info_size_swiper {
    gap: 4px;
  }

  .author-info__icon-wrapper_size_swiper {
    width: 14px;
    height: 14px;
  }

  .author-info__icon-wrapper_size_swiper .author-info__heart-icon {
    width: 12px;
    height: 10px;
  }

  .author-info__text_size_swiper {
    font-size: 12px;
    line-height: 1.17;
  }
}

@media screen and (max-width: 780px) {
  .author-info {
    gap: 4px;
  }

  .author-info__icon-wrapper {
    width: 14px;
    height: 14px;
  }

  .author-info__icon-wrapper_size_big {
    width: 18px;
    height: 18px;
  }

  .author-info__gems-icon {
    width: 12px;
    height: 12px;
  }

  .author-info__icon-wrapper_size_big .author-info__gems-icon {
    width: 16px;
    height: 16px;
  }

  .author-info__heart-icon {
    width: 12px;
    height: 10px;
  }

  .author-info__icon-wrapper_size_big .author-info__heart-icon {
    width: 16px;
    height: 14px;
  }

  .author-info__text {
    font-size: 12px;
    line-height: 1.17;
  }

  .author-info__text_size_big {
    font-size: 14px;
    line-height: 1.29;
  }
}
