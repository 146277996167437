.comment-form-popup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.comment-form-popup__title {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
  padding: 20px 20px 10px;
}

.comment-form-popup__comment-block {
  width: 100%;
  border-top: 1px solid var(--neutral-bg-opacity-primary);
  border-bottom: 1px solid var(--neutral-bg-opacity-primary);
  box-sizing: border-box;
  padding: 6px 0;
}

.comment-form-popup__comment-block_type_padding {
  padding: 6px 0 16px;
}

.comment-form-popup__input-block {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  padding: 20px;
}

.comment-form-popup__avatar {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}
