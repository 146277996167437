.verify-age-popup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  box-sizing: border-box;
  padding: 30px 40px;
}

.verify-age-popup__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.verify-age-popup__logo-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--main-active-primary);
}

.verify-age-popup__logo {
  width: 20px;
  height: 14px;
}

.verify-age-popup__logo-fill {
  fill: var(--neutral-contrast-active);
}

.verify-age-popup__subtitle {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
}

.verify-age-popup__title {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
}

.verify-age-popup__buttons {
  width: 100%;
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 780px) {
  .verify-age-popup {
    padding: 30px;
  }
}
