.comments-dropdown {
  width: 40vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--website-background-theme);
}

.comments-dropdown__title {
  color: var(--neutral-active-theme);
  font-size: 26px;
  font-weight: 300;
  line-height: 1.08;
  padding: 26px 20px 0;
}

.comments-dropdown__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px solid var(--neutral-bg-opacity-primary);
  border-bottom: 1px solid var(--neutral-bg-opacity-primary);
  box-sizing: border-box;
  padding: 16px 0;
}

.comments-dropdown__content::-webkit-scrollbar {
  display: none;
}

.comments-dropdown__input-block {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  padding: 0 20px 24px;
}

.comments-dropdown__avatar {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

@media screen and (max-width: 1440px) {
  .comments-dropdown {
    width: 100vw;
    max-width: 480px;
  }
}

@media screen and (max-width: 780px) {
  .comments-dropdown {
    max-width: 100%;
    gap: 16px;
  }

  .comments-dropdown__title {
    padding: 22px 20px 0;
  }

  .comments-dropdown__input-block {
    padding: 0 20px 20px;
  }
}
