.swiper-gallery {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.swiper-gallery__heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.swiper-gallery__heading_type_reader {
  justify-content: flex-start;
}

.swiper-gallery__title {
  color: var(--neutral-active-theme);
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
  white-space: nowrap;
}

.swiper-gallery__title_color_top {
  color: var(--main-active-primary);
}

.swiper-gallery__title_size_small {
  font-size: 26px;
  font-weight: 300;
  line-height: 1.15;
  letter-spacing: 0;
  text-transform: none;
}

.swiper-gallery__arrow-box {
  width: 100%;
  max-width: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--main-active-tertiary-theme);
  box-sizing: border-box;
  padding: 21px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.swiper-gallery__arrow-box_size_small {
  max-width: 34px;
  max-height: 34px;
  padding: 11px;
}

.swiper-gallery:hover .swiper-gallery__arrow-box {
  opacity: 1;
}

.swiper-gallery__arrow {
  width: 18px;
  height: 15px;
}

.swiper-gallery__arrow_size_small {
  width: 12px;
  height: 10px;
}

.swiper-gallery__arrow-fill {
  fill: var(--main-active-primary);
}

.swiper-gallery__line {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--neutral-line-primary-theme);
  box-sizing: border-box;
  padding-left: 4px;
  margin-left: 4px;
}

.swiper-gallery__line-icon {
  width: 7px;
  height: 7px;
  position: absolute;
}

.swiper-gallery__line-icon_position_left {
  left: 0;
}

.swiper-gallery__line-icon_position_right {
  right: 0;
}

.swiper-gallery__line-icon-fill {
  fill: var(--neutral-line-primary-theme);
}

.swiper-gallery__dots {
  width: 58.71%;
  max-width: 1032px;
  height: 46px;
  display: grid;
  grid-template-columns: repeat(var(--dots-list-length), 6px);
  grid-template-rows: repeat(3, 6px);
  justify-content: space-between;
  align-content: space-between;
  margin-left: auto;
}

.swiper-gallery__dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--main-active-secondary);
}

.swiper-gallery__more-box {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 12px;
  border-radius: 10px;
  background: var(--block-background-secondary);
  overflow: hidden;
}

.swiper-gallery__more-box_size_small {
  width: 234px;
}

.swiper-gallery__more-icon {
  width: 180px;
  height: 180px;
  position: absolute;
}

.swiper-gallery__more-icon_size_small {
  width: 140px;
  height: 140px;
}

.swiper-gallery__more-icon_position_left {
  top: 0;
  left: -90px;
}

.swiper-gallery__more-icon_size_small.swiper-gallery__more-icon_position_left {
  left: -70px;
}

.swiper-gallery__more-icon_position_right {
  bottom: 0;
  right: -90px;
}

.swiper-gallery__more-icon_size_small.swiper-gallery__more-icon_position_right {
  right: -70px;
}

.swiper-gallery__more-icon-fill {
  fill: var(--swiper-more-icon-fill);
}

.swiper-gallery__more-icon-stroke {
  stroke: var(--swiper-more-icon-stroke);
}

.swiper-gallery__more-arrow {
  width: 82px;
  height: 82px;
}

.swiper-gallery__more-arrow-fill {
  fill: var(--main-active-secondary);
}

.swiper-gallery__more-text {
  text-align: center;
  font-family: Oswald;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.13;
}

@media (pointer: coarse) {
  .swiper-gallery__arrow-box {
    opacity: 1;
  }
}

@media screen and (max-width: 1100px) {
  .swiper-gallery {
    gap: 30px;
  }

  .swiper-gallery__title {
    font-size: 48px;
    line-height: 1.17;
    letter-spacing: -0.96px;
  }

  .swiper-gallery__title_size_small {
    font-size: 26px;
    line-height: 1.15;
    letter-spacing: 0;
  }

  .swiper-gallery__arrow-box {
    max-width: 48px;
    max-height: 48px;
    padding: 16px;
  }

  .swiper-gallery__arrow-box_size_small {
    max-width: 34px;
    max-height: 34px;
    padding: 11px;
  }

  .swiper-gallery__arrow {
    width: 16px;
    height: 13px;
  }

  .swiper-gallery__arrow_size_small {
    width: 12px;
    height: 10px;
  }

  .swiper-gallery__more-box {
    width: 220px;
  }

  .swiper-gallery__more-box_size_small {
    width: 180px;
  }

  .swiper-gallery__more-icon {
    width: 140px;
    height: 140px;
  }

  .swiper-gallery__more-icon_size_small {
    width: 114px;
    height: 114px;
  }

  .swiper-gallery__more-icon_position_left {
    left: -70px;
  }

  .swiper-gallery__more-icon_size_small.swiper-gallery__more-icon_position_left {
    left: -57px;
  }

  .swiper-gallery__more-icon_position_right {
    right: -70px;
  }

  .swiper-gallery__more-icon_size_small.swiper-gallery__more-icon_position_right {
    right: -57px;
  }

  .swiper-gallery__more-arrow {
    width: 60px;
    height: 60px;
  }

  .swiper-gallery__more-text {
    font-size: 24px;
    line-height: 1.17;
  }
}

@media screen and (max-width: 780px) {
  .swiper-gallery {
    gap: 20px;
  }

  .swiper-gallery__heading {
    display: grid;
    grid-template-columns: minmax(min-content, max-content) 1fr;
    grid-template-areas: 'title line';
    gap: 10px;
  }

  .swiper-gallery__heading_type_more {
    grid-template-columns: minmax(min-content, max-content) 1fr 34px;
    grid-template-areas: 'title line arrow';
  }

  .swiper-gallery__heading_type_top {
    gap: 20px;
  }

  .swiper-gallery__title {
    grid-area: title;
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .swiper-gallery__title_size_small {
    font-size: 26px;
    line-height: 1.15;
    letter-spacing: 0;
  }

  .swiper-gallery__arrow-box {
    grid-area: arrow;
    max-width: 34px;
    max-height: 34px;
    padding: 11px;
  }

  .swiper-gallery__arrow {
    width: 12px;
    height: 10px;
  }

  .swiper-gallery__line {
    grid-area: line;
    padding: 0 4px;
    margin: 0;
  }

  .swiper-gallery__dots {
    grid-area: line;
    width: 100%;
    max-width: 380px;
    height: 28px;
    grid-template-columns: repeat(var(--dots-list-length), 4px);
    grid-template-rows: repeat(3, 4px);
    margin: 0 auto;
  }

  .swiper-gallery__more-box {
    width: 170px;
    gap: 8px;
  }

  .swiper-gallery__more-icon {
    width: 114px;
    height: 114px;
  }

  .swiper-gallery__more-icon_position_left {
    left: -57px;
  }

  .swiper-gallery__more-icon_position_right {
    right: -57px;
  }

  .swiper-gallery__more-arrow {
    width: 50px;
    height: 50px;
  }

  .swiper-gallery__more-text {
    font-size: 20px;
    line-height: 1.2;
  }
}
