.gallery-card {
  width: 298px;
  height: 100%;
  display: flex;
}

.gallery-card_size_small {
  width: 232px;
}

.gallery-card_type_grid {
  width: 100% !important;
}

.gallery-card__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 26px;
  border-radius: 10px;
  border: 1px solid var(--neutral-border-primary);
  background: var(--neutral-contrast-active-theme);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.gallery-card__container_size_small {
  gap: 20px;
}

.gallery-card__container_type_ai {
  align-items: center;
  border: 1px solid var(--neutral-bg-opacity-tertiary);
  background: var(--neutral-bg-opacity-tertiary);
}

.gallery-card__image-box {
  width: 100%;
  height: 360px;
  position: relative;
  z-index: 1;
}

.gallery-card__image-box_size_small {
  height: 280px;
}

.gallery-card__image-wrapper {
  width: 100%;
  height: inherit;
  display: flex;
  position: relative;
  z-index: 0;
}

.gallery-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-card__number-box {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px 0px 38px 0px;
  background: var(--main-active-primary);
  box-sizing: border-box;
  padding: 12px 24px 12px 16px;
}

.gallery-card__number {
  color: var(--neutral-contrast-active-theme);
  font-family: Oswald;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.07;
  letter-spacing: -0.6px;
  text-transform: uppercase;
}

.gallery-card__free-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 60px;
  background: var(--neutral-contrast-active-theme);
  box-sizing: border-box;
  padding: 5px 8px 3px 8px;
}

.gallery-card__free-text {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.17;
  text-transform: uppercase;
}

.gallery-card__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px 26px;
  z-index: 1;
}

.gallery-card__content_size_small {
  padding: 0 16px 20px;
}

.gallery-card__content-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gallery-card__subtitle {
  color: var(--second-active-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.gallery-card__subtitle_color_neutral {
  color: var(--neutral-active-theme);
  font-weight: 400;
}

.gallery-card__subtitle_color_ai {
  color: var(--main-active-primary);
}

.gallery-card__date {
  color: var(--neutral-active-theme);
  text-align: right;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  opacity: 0.6;
}

.gallery-card__save-button {
  width: 14px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-card__save-icon {
  width: 100%;
  height: 100%;
}

.gallery-card__save-icon-fill {
  fill: var(--neutral-bg-inactive);
  transition: fill 0.2s ease-in-out;
}

.gallery-card__save-icon-fill_active {
  fill: var(--main-active-primary);
}

.gallery-card__title {
  flex: auto;
  max-height: 68px;
  color: var(--neutral-active-theme);
  font-size: 30px;
  font-weight: 300;
  line-height: 1.13;
  padding: 14px 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gallery-card__title_size_small {
  max-height: 56px;
  font-size: 24px;
  line-height: 1.17;
}

.gallery-card__tags-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.gallery-card__tags-list {
  width: 100%;
  display: flex;
  position: relative;
}

.gallery-card__tag {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  background: var(--tag-color-primary);
  box-sizing: border-box;
  padding: 4px 12px;
  overflow: hidden;
}

.gallery-card__tag-text {
  color: var(--neutral-active-theme);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.17;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery-card__tag-text_type_color {
  color: var(--tag-color-secondary);
}

.gallery-card__info-block {
  margin-top: 10px;
}

.gallery-card__bg {
  width: 254px;
  height: 254px;
  border-radius: 50%;
  background: var(--ai-card-color-primary);
  filter: blur(85px);
  position: absolute;
  bottom: -45px;
  left: -95px;
  z-index: 0;
}

.gallery-card:nth-child(2n) .gallery-card__bg,
.swiper-block__slide:nth-child(2n) .gallery-card__bg {
  background: var(--ai-card-color-secondary);
  bottom: -45px;
  left: -95px;
  right: unset;
}

.gallery-card:nth-child(3n) .gallery-card__bg,
.swiper-block__slide:nth-child(3n) .gallery-card__bg {
  background: var(--ai-card-color-tertiary);
  bottom: -113px;
  right: -87px;
  left: unset;
}

.gallery-card:nth-child(4n) .gallery-card__bg,
.swiper-block__slide:nth-child(4n) .gallery-card__bg {
  background: var(--ai-card-color-quaternary);
  bottom: -105px;
  right: -63px;
  left: unset;
}

.gallery-card:nth-child(5n) .gallery-card__bg,
.swiper-block__slide:nth-child(5n) .gallery-card__bg {
  background: var(--ai-card-color-quinary);
  bottom: -142px;
  left: unset;
  right: unset;
}

@media screen and (max-width: 1100px) {
  .gallery-card {
    width: 220px;
  }

  .gallery-card_size_small {
    width: 180px;
  }

  .gallery-card__container {
    gap: 20px;
  }

  .gallery-card__container_size_small {
    gap: 16px;
  }

  .gallery-card__image-box {
    height: 260px;
  }

  .gallery-card__image-box_size_small {
    height: 220px;
  }

  .gallery-card__content {
    padding: 0 16px 16px;
  }

  .gallery-card__content_size_small {
    padding: 0 12px 16px;
  }

  .gallery-card__title {
    max-height: 52px;
    font-size: 22px;
    line-height: 1.17;
    padding: 10px 0 2px;
  }

  .gallery-card__tags-box {
    margin-top: 14px;
  }

  .gallery-card__tag {
    padding: 3px 8px;
  }

  .gallery-card__tag-text {
    font-size: 10px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 780px) {
  .gallery-card {
    width: 170px;
  }

  .gallery-card__container {
    gap: 14px;
  }

  .gallery-card__image-box {
    height: 204px;
  }

  .gallery-card__number-box {
    width: 42px;
    height: 42px;
    border-radius: 0px 0px 26px 0px;
    padding: 10px 18px 12px 13px;
  }

  .gallery-card__number {
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: -0.4px;
  }

  .gallery-card__free-box {
    bottom: 6px;
    right: 6px;
    padding: 3px 6px;
  }

  .gallery-card__free-text {
    font-size: 10px;
    line-height: 1.2;
  }

  .gallery-card__content {
    padding: 0 12px 12px;
  }

  .gallery-card__subtitle {
    font-size: 12px;
    line-height: 1.17;
  }

  .gallery-card__save-button {
    width: 12px;
    height: 14px;
  }

  .gallery-card__title {
    max-height: 44px;
    font-size: 18px;
    line-height: 1.22;
    padding: 6px 0 1px;
  }

  .gallery-card__bg {
    bottom: -107px;
    left: -144px;
  }

  .gallery-card:nth-child(2n) .gallery-card__bg {
    bottom: -103px;
    left: -96px;
    right: unset;
  }

  .gallery-card:nth-child(3n) .gallery-card__bg {
    bottom: -63px;
    left: 0;
    right: unset;
  }

  .gallery-card:nth-child(4n) .gallery-card__bg {
    bottom: -55px;
    right: -83px;
    left: unset;
  }

  .gallery-card:nth-child(5n) .gallery-card__bg {
    bottom: -142px;
    left: unset;
    right: unset;
  }
}
