.auth-account {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.auth-account__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.auth-account__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.auth-account__title-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.auth-account__title {
  color: var(--neutral-active-theme);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.auth-account__subtitle {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

.auth-account__btn {
  width: fit-content;
  min-width: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid var(--neutral-border-secondary);
  background: var(--neutral-contrast-active-theme);
  box-sizing: border-box;
  padding: 10px 24px;

  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;

  transition: opacity 0.3s ease-in-out;
}

.auth-account__btn_type_disconnect {
  background: var(--neutral-border-secondary);
}
