.creator-card {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding-bottom: 8px;
}

.creator-card__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creator-card__img {
  min-width: 220px;
  max-width: 220px;
  min-height: 220px;
  max-height: 220px;
}

.creator-card__title {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 16px;
}

.creator-card__subtitle {
  color: var(--second-active-tertiary);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  padding: 8px 0 12px;
}

@media screen and (max-width: 1100px) {
  .creator-card__img {
    min-width: 180px;
    max-width: 180px;
    min-height: 180px;
    max-height: 180px;
  }

  .creator-card__title {
    font-size: 18px;
  }

  .creator-card__subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 780px) {
  .creator-card {
    padding-bottom: 30px;
  }

  .creator-card__img {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
  }

  .creator-card__title {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px;
  }

  .creator-card__subtitle {
    font-size: 12px;
    line-height: 1.33;
    padding: 4px 0 6px;
  }
}
