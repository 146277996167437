.line-divider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.line-divider__main-icon {
  width: 26px;
  height: 25px;
  position: absolute;
  z-index: 2;
}

.line-divider__main-icon-fill {
  fill: var(--rhomb-color);
}

.line-divider__line {
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--line-color);
  position: relative;
  z-index: 1;
  will-change: width;
}

.line-divider__line-icon {
  width: 7px;
  height: 7px;
  position: absolute;
}

.line-divider__line-icon_position_left {
  left: -3px;
}

.line-divider__line-icon_position_right {
  right: -3px;
}

.line-divider__line-icon-fill {
  fill: var(--line-color);
}

@media screen and (max-width: 1100px) {
  .line-divider__main-icon {
    width: 18px;
    height: 17px;
  }
}

@media screen and (max-width: 780px) {
  .line-divider__main-icon {
    width: 16px;
    height: 15px;
  }

  .line-divider__main-icon_size_small {
    width: 10px;
    height: 9px;
  }

  .line-divider__line-icon {
    width: 5px;
    height: 5px;
  }
}
