.mobile-popup {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  visibility: hidden;
  transition: all 0.6s ease-in-out;
}

.mobile-popup_type_fit-content {
  align-items: flex-end;
}

.mobile-popup__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  position: relative;
  overflow: scroll;
  background: var(--website-background-theme);
  box-sizing: border-box;
  z-index: 1000;
}

.mobile-popup__container::-webkit-scrollbar {
  display: none;
}

.mobile-popup__container_type_modal {
  height: fit-content;
  border-radius: 20px;
  box-shadow: 0px 14px 24px 0px var(--box-shadow-primary-theme);
  margin: 16px;
}

.mobile-popup__container_type_fit-content {
  height: fit-content;
  overflow: visible;
  border-radius: 30px 30px 0 0;
}

.mobile-popup__container_type_small-radius {
  border-radius: 12px 12px 0 0;
}

.mobile-popup__container_type_with-copyright {
  padding-bottom: 30px;
}

.mobile-popup__heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 16px;
  left: 0;
  box-sizing: border-box;
  padding: 0 16px;
}

.mobile-popup__icon-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
}

.mobile-popup__icon-btn_type_close {
  border: none;
  background: var(--neutral-bg-opacity-primary);
  margin-left: auto;
}

.mobile-popup__logo-icon {
  width: 20px;
  height: 14px;
}

.mobile-popup__close-icon {
  width: 14px;
  height: 14px;
}

.mobile-popup__icon-fill {
  fill: var(--neutral-active-theme);
}

.mobile-popup__copyright {
  color: var(--neutral-quaternary);
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4;
}

.mobile-popup__dots-wrapper {
  width: 100%;
  max-width: 380px;
  position: absolute;
  top: -72px;
  box-sizing: border-box;
  padding: 0 16px;
}

.mobile-popup__dots {
  width: 100%;
  height: 52px;
  display: grid;
  grid-template-columns: repeat(10, 6px);
  grid-template-rows: repeat(3, 6px);
  justify-content: space-between;
  align-content: space-between;
}

.mobile-popup__dot {
  width: 100%;
  height: 100%;
  background: var(--second-active-quaternary);
  border-radius: 50%;
}

.mobile-popup__dot_type_tooltip {
  background: var(--creator-color-primary);
}

.mobile-popup__overlay {
  width: 100%;
  height: 100%;
  background: var(--popup-background);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.mobile-popup__overlay_type_modal {
  backdrop-filter: blur(8px);
}
