.continue-block {
  width: 100vw;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--block-background-primary);
  box-sizing: border-box;
  padding: 60px 64px;
  margin-top: -100px;
}

.continue-block__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.continue-block__title {
  width: 100%;
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: -0.8px;
  text-transform: uppercase;
}

@media screen and (max-width: 1440px) {
  .continue-block {
    padding: 60px 32px;
  }

  .continue-block__container {
    max-width: var(--container-max-width);
  }
}

@media screen and (max-width: 1100px) {
  .continue-block {
    margin-top: -80px;
  }
}

@media screen and (max-width: 780px) {
  .continue-block {
    padding: 40px 16px;
    margin-top: -40px;
  }

  .continue-block__container {
    gap: 20px;
  }

  .continue-block__title {
    font-size: 26px;
    line-height: 1.15;
    letter-spacing: -0.52px;
  }
}
