.categories-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.categories-list__heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
}

.categories-list__title {
  color: var(--neutral-active-theme);
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
  white-space: nowrap;
}

.categories-list__dots {
  width: 78.5%;
  max-width: 1220px;
  height: 62px;
  display: grid;
  grid-template-columns: repeat(var(--dots-list-length), 6px);
  grid-template-rows: repeat(3, 6px);
  justify-content: space-between;
  align-content: space-between;
  margin-left: auto;
}

.categories-list__dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--second-active-quaternary);
}

.categories-list__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categories-list__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.categories-list__line {
  width: 100%;
  display: flex;
  align-items: center;
}

.categories-list__icon-box {
  width: 100%;
  max-width: 68px;
  max-height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--main-active-primary);
  box-sizing: border-box;
  padding: 14px 19px;
}

.categories-list__icon {
  width: 30px;
  height: 40px;
}

.categories-list__icon-fill {
  fill: var(--neutral-contrast-active);
}

.categories-list__category-box {
  width: fit-content;
  display: flex;
}

.categories-list__category-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 600px;
  border: 1px solid var(--main-active-secondary);
  box-sizing: border-box;
  padding: 18px 28px;
}

.categories-list__line:last-child
  .categories-list__category-box:last-child
  .categories-list__category-link {
  background: var(--main-active-secondary);
}

.categories-list__text {
  color: var(--neutral-active-theme);
  white-space: nowrap;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.23;
}

.categories-list__arrow {
  width: 11px;
  height: 11px;
}

.categories-list__arrow-fill {
  fill: var(--neutral-active-theme);
}

.categories-list__no-data {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
}

@media screen and (max-width: 1440px) {
  .categories-list__dots {
    width: 69.92%;
  }
}

@media screen and (max-width: 1100px) {
  .categories-list {
    gap: 30px;
  }

  .categories-list__title {
    font-size: 48px;
    line-height: 1.17;
    letter-spacing: -0.96px;
  }

  .categories-list__dots {
    height: 54px;
  }

  .categories-list__icon-box {
    max-width: 54px;
    max-height: 54px;
    padding: 10px 14px;
  }

  .categories-list__icon {
    width: 24px;
    height: 32px;
  }

  .categories-list__category-link {
    padding: 14px 16px;
  }

  .categories-list__text {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 780px) {
  .categories-list {
    gap: 20px;
  }

  .categories-list__title {
    font-size: 32px;
    line-height: 1.07;
    letter-spacing: -0.64px;
  }

  .categories-list__dots {
    height: 28px;
    grid-template-columns: repeat(var(--dots-list-length), 4px);
    grid-template-rows: repeat(3, 4px);
  }

  .categories-list__icon-box {
    max-width: 26px;
    max-height: 26px;
    padding: 4px 6px;
  }

  .categories-list__icon {
    width: 14px;
    height: 18px;
  }

  .categories-list__category-link {
    gap: 6px;
    padding: 6px 10px;
  }

  .categories-list__text {
    font-size: 12px;
    line-height: 1.17;
  }

  .categories-list__arrow {
    width: 6px;
    height: 6px;
  }
}
