.error-message {
  display: flex;
  align-items: center;
  gap: 4px;
}

.error-message__icon {
  width: 13px;
  height: 12px;
}

.error-message__icon-fill {
  fill: var(--main-active-primary);
}

.error-message__text {
  color: var(--main-active-primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.16;
  padding: 0 16px;
}
