.story-card {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 42px minMax(0, 1fr);
  align-items: center;
  gap: 10px;
}

.story-card__image {
  width: 100%;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.story-card__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.story-card__subtitle {
  color: var(--neutral-active-theme);
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.story-card__title {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
