.animated-author-card {
  will-change: width;
  margin-left: -20px;
}

.animated-author-card:first-child {
  margin-left: 0;
}

.animated-author-card__link {
  display: grid;
  grid-template-columns: 144px 1fr;
  grid-template-rows: 144px;
  align-items: center;
}

.animated-author-card__btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  border: 4px solid var(--neutral-contrast-active);
  box-sizing: border-box;
  overflow: hidden;
}

.animated-author-card__text-block {
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: var(--neutral-bg-secondary);
  will-change: opacity, width;
  margin-left: -72px;
  z-index: 0;
}

.animated-author-card:last-child .animated-author-card__text-block {
  border-radius: 0 600px 600px 0;
}

.animated-author-card__text-box {
  width: calc(100% - 144px);
  min-width: 196px;
  height: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 0 16px;
}

.animated-author-card__text-box_type_last {
  width: calc(100% - 72px);
  right: 0;
}

.animated-author-card__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.animated-author-card__subtitle {
  color: var(--second-active-tertiary);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  padding: 8px 0 12px;
}
