.achievement-tooltip {
  min-width: 370px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  padding: 12px;
}

.achievement-tooltip__icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.achievement-tooltip__content {
  display: flex;
  flex-direction: column;
}

.achievement-tooltip__subtitle {
  color: var(--creator-neutral-primary);
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.achievement-tooltip__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
  padding-top: 8px;
}

.achievement-tooltip__description {
  color: var(--creator-neutral-primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 4px;
}

@media screen and (max-width: 1100px) {
  .achievement-tooltip {
    min-width: 280px;
    gap: 8px;
    padding: 8px;
  }

  .achievement-tooltip__subtitle {
    font-size: 8px;
    letter-spacing: 0.6px;
  }

  .achievement-tooltip__title {
    font-size: 14px;
    padding-top: 4px;
  }

  .achievement-tooltip__description {
    font-size: 10px;
    line-height: 1.27;
  }
}

@media screen and (max-width: 780px) {
  .achievement-tooltip {
    min-width: 100%;
    flex-direction: column;
    gap: 0;
    padding: 30px 40px 40px;
  }

  .achievement-tooltip__icon {
    width: 100px;
    height: 100px;
    margin-top: 24px;
  }

  .achievement-tooltip__content {
    align-items: center;
    margin-top: 12px;
  }

  .achievement-tooltip__subtitle {
    text-align: center;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.96px;
  }

  .achievement-tooltip__title {
    text-align: center;
    font-size: 22px;
    line-height: 1.18;
    padding-top: 0;
  }

  .achievement-tooltip__description {
    text-align: center;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.12px;
    padding-top: 0;
  }

  .achievement-tooltip__divider-box {
    width: 100%;
    margin: 26px 0;
  }
}
