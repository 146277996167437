.footer {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 64px 80px;
  z-index: 1;
}

.footer__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.footer__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__links-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.footer__icon-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.footer__icon-link_inverted {
  background-color: var(--neutral-active-theme);
}

.footer__logo-icon {
  width: 20px;
  height: 14px;
}

.footer__logo-icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.footer__link-icon {
  width: 100%;
  height: 100%;
}

.footer__link-icon-background {
  fill: var(--website-background-theme);
}

.footer__link-icon-background_theme_dark {
  fill: var(--neutral-active-theme);
}

.footer__link-icon-fill {
  fill: var(--neutral-active-theme);
}

.footer__link-icon-fill_theme_dark {
  fill: var(--neutral-contrast-active-theme);
}

.footer__link-icon-stroke {
  stroke: var(--neutral-active-theme);
}

.footer__link-icon-stroke_theme_dark {
  stroke: var(--website-background-theme);
}

.footer__btn {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 60px;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
  padding: 10px 23px;

  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28;
  white-space: nowrap;
}

.footer__btn_inverted {
  background: var(--neutral-active-theme);
  color: var(--neutral-contrast-active-theme);
}

.footer__author-icon {
  width: 12px;
  height: 18px;
}

.footer__author-icon-fill {
  fill: var(--neutral-contrast-active-theme);
}

.footer__link-item_type_mobile {
  display: none;
}

.footer__content-block {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer__copyright {
  color: var(--neutral-secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
}

.footer__divider {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--neutral-line-primary-theme);
}

.footer__divider-icon {
  width: 7px;
  height: 7px;
  position: absolute;
}

.footer__divider-icon_position_left {
  left: 0;
}

.footer__divider-icon_position_right {
  right: 0;
}

.footer__divider-icon-fill {
  fill: var(--neutral-line-primary-theme);
}

.footer__categories-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer__categories-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, max-content);
  grid-auto-columns: minmax(min-content, max-content);
  grid-template-rows: repeat(var(--category-rows), 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 14px 32px;
}

.footer__category-text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28;
}

@media screen and (max-width: 1440px) {
  .footer {
    padding: 0 32px 80px;
  }

  .footer__container {
    max-width: var(--container-max-width);
  }
}

@media screen and (max-width: 1100px) {
  .footer {
    padding: 0 32px 60px;
  }

  .footer__container {
    gap: 30px;
  }

  .footer__content {
    flex-direction: column;
  }

  .footer__content-block {
    flex-direction: column-reverse;
    gap: 26px;
  }

  .footer__link-item_type_desktop {
    display: none;
  }

  .footer__link-item_type_mobile {
    display: list-item;
  }

  .footer__links-list_type_social {
    position: relative;
    left: 16px;
  }

  .footer__copyright {
    line-height: 1.17;
  }
}

@media screen and (max-width: 780px) {
  .footer {
    padding: 0 16px 30px;
  }

  .footer__content {
    width: calc(100% + 10px);
  }
}
