.ai-block {
  width: 100%;
  display: grid;
  grid-template-columns: 240px 1fr 42px;
  align-items: center;
  gap: 80px;
  border-radius: 26px;
  border: 0.4px solid var(--neutral-active-theme);
  background: var(--block-background-quaternary);
  backdrop-filter: blur(17px);
  box-sizing: border-box;
  padding: 10px 60px 10px 10px;
  position: relative;
  z-index: 1;
}

.ai-block__image-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
}

.ai-block__banner-text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;

  position: absolute;
  top: 42px;
}

.ai-block__banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ai-block__form-box {
  width: 100%;
  height: 100%;
  min-height: 474px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 0;
}

.ai-block__btns {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.ai-block__btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.ai-block__btn {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--neutral-bg-opacity);
  backdrop-filter: blur(5.5px);
}

.ai-block__btn-icon {
  width: 22px;
  height: 22px;
}

.ai-block__btn-icon-stroke {
  stroke: var(--main-active-primary);
}

.ai-block__btn-label {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.2;

  position: absolute;
  bottom: -20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ai-block__btn:hover + .ai-block__btn-label {
  opacity: 1;
}

@media (pointer: coarse) {
  .ai-block__btn-label {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .ai-block {
    grid-template-columns: 1fr 42px;
    gap: 40px;
    padding: 30px;
  }

  .ai-block__image-box {
    display: none;
  }

  .ai-block__form-box {
    min-height: 466px;
    padding: 0;
  }

  .ai-block__btns {
    gap: 40px;
  }
}

@media screen and (max-width: 780px) {
  .ai-block {
    display: flex;
    flex-direction: column-reverse;
    gap: 22px;
    border-radius: 16px;
    padding: 12px 20px 20px;
  }

  .ai-block__btns {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .ai-block__btn {
    width: 32px;
    height: 32px;
  }

  .ai-block__btn-label {
    display: none;
  }
}
