.static-docs {
  width: 100%;
  max-width: var(--container-max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 80px 64px;
}

.static-docs__close-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 64px;
  right: 64px;
  border-radius: 50%;
  background-color: var(--neutral-bg-opacity-primary);
}

.static-docs__close-icon {
  width: 16px;
  height: 16px;
}

.static-docs__close-icon-fill {
  fill: var(--neutral-active);
}

.static-docs__content {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.static-docs__title {
  font-size: 84px;
  line-height: 1.05;
  letter-spacing: -1.68px;
  text-transform: uppercase;
}

.static-docs__text {
  color: var(--neutral-active);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.static-docs__text section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.static-docs__text ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.static-docs__text span {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.15;
}

@media screen and (max-width: 1440px) {
  .static-docs {
    padding: 80px 32px;
  }

  .static-docs__close-btn {
    top: 40px;
    right: 40px;
  }
}

@media screen and (max-width: 780px) {
  .static-docs {
    padding: 40px 16px;
  }

  .static-docs__close-btn {
    top: 16px;
    right: 16px;
  }

  .static-docs__title {
    font-size: 64px;
    line-height: 1.06;
    letter-spacing: -1.28px;
  }
}
