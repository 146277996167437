.desk {
  width: 100vw;
  max-width: calc(var(--max-width) + 64px * 2);
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 64px 72px;
  margin-top: 8px;
}

.desk__container {
  width: 100%;
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 40px;
  border-top: 1px solid var(--neutral-line-primary);
  box-sizing: border-box;
  padding-top: 40px;
}

.desk__sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.desk__heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.desk__user-info {
  width: 100%;
  min-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.desk__user-name {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.08;
}

.desk__user-type-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--neutral-border-tertiary);
  box-sizing: border-box;
  padding: 3px 8px;
}

.desk__user-type {
  color: var(--main-active-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}

.desk__content {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .desk {
    max-width: calc(var(--container-max-width) + 32px * 2);
    min-height: calc(100dvh - 64px);
    padding: 0 32px 72px;
  }
}

@media screen and (max-width: 1100px) {
  .desk__container {
    grid-template-columns: 220px 1fr;
    gap: 30px;
    padding-top: 30px;
  }
}

@media screen and (max-width: 780px) {
  .desk {
    padding: 20px 16px 40px;
  }

  .desk__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: none;
    padding-top: 0;
  }

  .desk__title {
    font-size: 32px;
    line-height: 1.06;
    letter-spacing: -0.64px;
    text-transform: uppercase;
  }

  .desk__user-info {
    width: fit-content;
    min-height: 17px;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .desk__user-name {
    font-size: 16px;
  }

  .desk__user-type-wrapper {
    border-radius: 5px;
    padding: 1px 7px;
  }

  .desk__user-type {
    font-size: 10px;
    line-height: 1.2;
  }
}
