.dropdown {
  position: absolute;
  top: 46px;
  left: 0;
  border-radius: 10px;
  z-index: 1000;
  overflow: hidden;
}

.dropdown_open_from-right {
  right: 0;
  left: unset;
}

.dropdown_open_from-top {
  left: unset;
  margin-right: 52px;
}

.dropdown_position_fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  border-radius: 24px 0px 0px 24px;
  box-shadow: -30px 0px 44px 0px var(--box-shadow-primary-theme);
}

.dropdown_type_tooltip {
  border-radius: 16px;
  box-shadow: 0px 14px 24px 0px var(--box-shadow-primary-theme);
  left: 105px;
  top: unset;
  bottom: unset;
}

.dropdown__content {
  max-height: 80dvh;
  background: var(--website-background-theme);
  overflow: scroll;
}

.dropdown__content_full-height {
  max-height: 100dvh;
}

.dropdown__content::-webkit-scrollbar {
  display: none;
}

.dropdown__close-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--neutral-bg-opacity-primary);
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown__close-icon {
  width: 14px;
  height: 14px;
}

.dropdown__close-icon-fill {
  fill: var(--neutral-active-theme);
}

.dropdown__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
