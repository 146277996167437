.confirm-email-block_type_border {
  border-top: 1px solid var(--neutral-border-primary) !important;
  border-bottom: 1px solid var(--neutral-border-primary) !important;
  padding: 30px 0 !important;
}

@media screen and (max-width: 780px) {
  .confirm-email-block_type_border {
    padding: 20px 0 !important;
  }
}
