.continue-card {
  width: 406px;
  height: 100%;
  display: flex;
}

.continue-card__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 26px;
  border-radius: 10px;
  border: 1px solid;
  background: var(--website-background);
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 22px;
}

.continue-card__image-wrapper {
  width: 100%;
  max-width: 156px;
  height: 188px;
  display: flex;
}

.continue-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.continue-card__content {
  width: 100%;
  height: 100%;
  max-height: 104px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.continue-card__link {
  width: fit-content;
}

.continue-card__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
}

.continue-card__title {
  flex: auto;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.continue-card__progress-line {
  width: 100%;
  max-width: 196px;
  height: 3px;
  border-radius: 1.5px;
  background: var(--neutral-line-secondary);
  position: relative;
  overflow: hidden;
}

.continue-card__progress {
  height: 100%;
  background: var(--main-active-secondary);
  position: absolute;
}

@media screen and (max-width: 780px) {
  .continue-card {
    width: 320px;
  }

  .continue-card__container {
    gap: 20px;
    border: none;
    padding-right: 20px;
  }

  .continue-card__image-wrapper {
    max-width: 100px;
    height: 120px;
  }

  .continue-card__content {
    max-height: 92px;
    gap: 8px;
  }

  .continue-card__subtitle {
    font-size: 12px;
    line-height: 1.17;
  }

  .continue-card__title {
    font-size: 22px;
  }

  .continue-card__progress-line {
    margin-top: 6px;
  }
}
