.quote-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quote-element__top-line {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
}

.quote-element__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  color: var(--neutral-active-theme);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.625;
}

.quote-element__bottom-line {
  width: 100%;
  margin-top: 4px;
}

@media screen and (max-width: 780px) {
  .quote-element__text {
    font-size: 14px;
    line-height: 1.428;
  }
}
