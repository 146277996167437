.creator-heading {
  width: 100%;
  min-height: 306px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: var(--main-active-tertiary);
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  z-index: 2;
}

.creator-heading__link-btn {
  position: absolute;
  left: 20px;
  top: 20px;
}

.creator-heading__btn {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  z-index: 1;
}

.creator-heading__btn_position_right {
  flex-direction: row-reverse;
  position: absolute;
  right: 20px;
  top: 20px;
}

.creator-heading__btn-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
}

.creator-heading__btn-text_position_right {
  text-align: right;
}

.creator-heading__icon-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-active);
  background-color: var(--neutral-bg-opacity);
  transition: background-color 0.3s ease-in-out;
}

.creator-heading__icon-box_type_color {
  border-color: var(--main-active-primary);
}

.creator-heading__icon-box_active {
  background-color: var(--main-active-primary);
}

.creator-heading__back-icon {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.creator-heading__back-icon-fill {
  fill: var(--neutral-active);
}

.creator-heading__bookmark-icon {
  width: 13px;
  height: 16px;
}

.creator-heading__bookmark-icon-fill {
  fill: var(--main-active-primary);
  transition: fill 0.3s ease-in-out;
}

.creator-heading__bookmark-icon-fill_active {
  fill: var(--neutral-contrast-active);
}

.creator-heading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.creator-heading__avatar {
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
}

.creator-heading__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.15;
  padding: 16px 0 10px;
}

.creator-heading__achievements {
  display: flex;
  gap: 12px;
  position: absolute;
  bottom: -58px;
  z-index: 1;
}

.creator-heading__bg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.creator-heading__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.creator-heading__skeleton-loader {
  background-color: var(--main-active-tertiary);
}

@media screen and (max-width: 780px) {
  .creator-heading {
    min-height: 270px;
    padding: 12px;
  }

  .creator-heading__link-btn {
    left: 12px;
    top: 12px;
  }

  .creator-heading__btn_position_right {
    right: 12px;
    top: 12px;
  }

  .creator-heading__btn-text {
    display: none;
  }

  .creator-heading__avatar {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
  }

  .creator-heading__title {
    font-size: 22px;
    line-height: 1.18;
  }
}
