.profile-dropdown {
  min-width: 240px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px 16px 12px;
}

.profile-dropdown__heading {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.profile-dropdown__avatar {
  display: none !important;
}

.profile-dropdown__title {
  color: var(--neutral-active-theme);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.125;
}

.profile-dropdown__subtitle {
  color: var(--neutral-active-theme);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.17;
}

.profile-dropdown__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.profile-dropdown__item {
  width: 100%;
  border-top: 1px solid var(--neutral-bg-opacity-primary);
  padding-top: 12px;
}

.profile-dropdown__link {
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-dropdown__link-icon {
  width: 18px;
  height: 18px;
}

.profile-dropdown__link-icon-stroke {
  stroke: var(--neutral-active-theme);
}

.profile-dropdown__link-label {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
}

@media screen and (max-width: 780px) {
  .profile-dropdown {
    width: 100%;
    min-width: unset;
    padding: 56px 40px 40px;
  }

  .profile-dropdown__heading {
    align-items: center;
    gap: 8px;
  }

  .profile-dropdown__avatar {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    display: flex !important;
  }

  .profile-dropdown__title {
    text-align: center;
    font-size: 26px;
    padding-top: 4px;
  }

  .profile-dropdown__subtitle {
    font-size: 14px;
    text-align: center;
  }

  .profile-dropdown__list {
    gap: 16px;
  }

  .profile-dropdown__item {
    padding-top: 16px;
  }

  .profile-dropdown__link-icon {
    width: 24px;
    height: 24px;
  }

  .profile-dropdown__link-label {
    font-size: 20px;
    line-height: 1.2;
  }
}
