.author-card {
  max-width: 220px;
}

.author-card__link {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.author-card__img {
  min-width: 64px;
  max-width: 64px;
  min-height: 64px;
  max-height: 64px;
}

.author-card__text-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.author-card__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.author-card__subtitle {
  color: var(--second-active-tertiary);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
}
