.preferences-popup {
  width: 100%;
  max-width: calc(var(--container-max-width) + 32px * 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  box-sizing: border-box;
  padding: 58px 32px 40px;
}

.preferences-popup__heading {
  display: flex;
  align-items: center;
  gap: 16px;
}

.preferences-popup__icon-box {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-active-primary);
  border-radius: 50%;
}

.preferences-popup__icon {
  width: 30px;
  height: 40px;
}

.preferences-popup__icon-fill {
  fill: var(--neutral-contrast-active);
}

.preferences-popup__title {
  color: var(--neutral-active-theme);
  font-size: 60px;
  font-weight: 200;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.preferences-popup__content {
  width: 100%;
  max-width: 672px;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.preferences-popup__content::-webkit-scrollbar {
  display: none;
}

.preferences-popup__btns {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}

@media screen and (max-width: 780px) {
  .preferences-popup {
    gap: 40px;
    padding: 100px 24px 60px;
  }

  .preferences-popup__heading {
    gap: 12px;
  }

  .preferences-popup__icon-box {
    width: 42px;
    height: 42px;
  }

  .preferences-popup__icon {
    width: 14px;
    height: 18px;
  }

  .preferences-popup__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .preferences-popup__content {
    max-width: 472px;
  }

  .preferences-popup__btns {
    max-width: 400px;
    margin: 0 auto;
  }
}
