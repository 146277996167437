.achievement {
  display: flex;
  align-items: center;
  position: relative;
}

.achievement__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.achievement__icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.achievement__title {
  color: var(--creator-neutral-primary);
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
}
