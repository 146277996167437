.ai-form {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.ai-form__container {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ai-form__heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1px;
  row-gap: 6px;
  box-sizing: border-box;
}

.ai-form__logo-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--neutral-active-theme);
}

.ai-form__logo-btn_theme_dark {
  background-color: var(--main-active-primary);
}

.ai-form__logo {
  width: 20px;
  height: 14px;
}

.ai-form__logo-fill {
  fill: var(--neutral-contrast-active);
}

.ai-form__avatar {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.ai-form__data-item {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 60px;
  border: 1px solid var(--neutral-active-theme);
  box-sizing: border-box;
  padding: 11px 24px;
  max-width: 100%;
  overflow: hidden;
}

.ai-form__data-value {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ai-form__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ai-form__content-box {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.ai-form__text-box {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.ai-form__title {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.ai-form__title_size_medium {
  text-align: left;
  font-size: 30px;
  line-height: 1.13;
  letter-spacing: -0.6px;
}

.ai-form__title_size_small {
  text-align: left;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.4px;
}

.ai-form__title_width_restricted {
  max-width: 342px;
}

.ai-form__subtitle {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  padding-top: 12px;
}

.ai-form__story-box {
  margin-top: 20px;
}

.ai-form__text {
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  padding-top: 8px;
}

.ai-form__text_type_stories {
  flex: auto;
  padding-top: 20px;
}

.ai-form__instruction {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  background: var(--ai-neutral-secondary);
  backdrop-filter: blur(5.5px);
  box-sizing: border-box;
  padding: 18px 16px;
  margin-top: 30px;
}

.ai-form__instruction-number {
  color: var(--neutral-active-dark-theme);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

.ai-form__instruction-text {
  color: var(--neutral-active-dark-theme);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.ai-form__loader {
  margin-top: 20px;
}

.ai-form__more-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-contrast-active);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  white-space: nowrap;
  border-radius: 60px;
  background: var(--main-active-primary);
  box-sizing: border-box;
  padding: 11px 24px;
  margin-top: 40px;
}

.ai-form__form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 600px;
  border: 1px solid var(--neutral-line-primary-dark-theme);
  box-sizing: border-box;
  padding: 4px 4px 4px 20px;
  transition: all 0.2s ease-in-out;
}

.ai-form__form_first-not-triggered {
  animation: pulse-animation 3.5s infinite;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    border-color: var(--neutral-line-primary);
  }
  50% {
    transform: scale(1.01);
    border-color: var(--main-active-primary);
  }
  100% {
    transform: scale(1);
    border-color: var(--neutral-line-primary);
  }
}

.ai-form__input {
  width: 100%;
  color: var(--neutral-active-theme);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  caret-color: var(--neutral-active-theme) !important;
}

.ai-form__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--website-background-theme) inset !important;
  /* Forces the background to white */
  -webkit-text-fill-color: var(--neutral-active-theme) !important;
  /* Ensures the text color remains black */
  border: none;
  /* Optional: Adjust the border color if needed */
}

.ai-form__input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--website-background-theme) inset !important;
  /* Ensures consistency on focus */
  -webkit-text-fill-color: var(--neutral-active-theme) !important;
}

.ai-form__input {
  transition: background-color 0s ease-in-out 0s;
  /* Ensures background transitions smoothly */
}

.ai-form__input::placeholder {
  color: var(--neutral-active-theme);
  opacity: 0.7;
}

.ai-form__submit-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--ai-color-primary);
  box-sizing: border-box;
  padding: 10px 12px;
  transition: all 0.3s ease-in-out;
}

.ai-form__submit-btn_disabled {
  background: var(--ai-color-secondary);
  pointer-events: none;
  user-select: none;
}

.ai-form__submit-btn_theme_dark.ai-form__submit-btn_disabled {
  background: var(--ai-color-secondary-dark-theme);
}

.ai-form__submit-icon {
  width: 20px;
  height: 16px;
}

.ai-form__submit-icon-fill {
  fill: var(--neutral-contrast-active);
}

@media screen and (max-width: 1440px) {
  .ai-form {
    max-width: 620px;
  }
}

@media screen and (max-width: 1100px) {
  .ai-form {
    max-width: 100%;
  }

  .ai-form__title {
    width: 100%;
    font-size: 48px;
    letter-spacing: -0.96px;
  }

  .ai-form__title_size_medium {
    font-size: 30px;
    letter-spacing: -0.6px;
  }

  .ai-form__title_size_small {
    font-size: 20px;
    letter-spacing: -0.4px;
  }

  .ai-form__more-btn {
    margin-top: 24px;
  }
}

@media screen and (max-width: 780px) {
  .ai-form {
    gap: 16px;
  }

  .ai-form__container {
    gap: 16px;
  }

  .ai-form__title {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .ai-form__title_size_medium {
    font-size: 24px;
    letter-spacing: -0.48px;
  }

  .ai-form__title_size_small {
    font-size: 18px;
    letter-spacing: -0.36px;
  }

  .ai-form__title_width_restricted {
    max-width: 220px;
  }

  .ai-form__text_type_stories {
    padding-top: 12px;
  }

  .ai-form__instruction {
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 16px;
  }

  .ai-form__instruction-text {
    font-size: 14px;
    line-height: 1.43;
  }

  .ai-form__more-btn {
    margin-top: 16px;
  }
}
