.message-popup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  box-sizing: border-box;
  padding: 30px 40px 20px;
}

.message-popup__heading {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.message-popup__logo-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--neutral-active-theme);
  box-sizing: border-box;
}

.message-popup__logo {
  width: 20px;
  height: 14px;
}

.message-popup__logo-fill {
  fill: var(--neutral-contrast-active-theme);
}

.message-popup__title {
  color: var(--neutral-active-theme);
  font-size: 38px;
  font-weight: 300;
  line-height: 1.05;
}

.message-popup__text {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.44;
}

@media screen and (max-width: 780px) {
  .message-popup {
    padding: 40px 32px 40px;
  }
}
