.success-message {
  display: flex;
  align-items: center;
  gap: 4px;
}

.success-message__text {
  color: var(--second-active-primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.16;
  padding: 0 16px;
}