.pagination-bar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--website-background);
  box-sizing: border-box;
  padding: 4px;
  z-index: 10;
}

.pagination-bar_theme_dark {
  background-color: var(--pagination-neutral-primary);
}

.pagination-bar__list {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination-bar__btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.pagination-bar__btn_type_close {
  width: 100%;
  max-width: 40px;
  max-height: 40px;
  background-color: var(--neutral-bg-opacity-primary);
}

.pagination-bar__close-icon {
  width: 11px;
  height: 11px;
}

.pagination-bar__close-icon-fill {
  fill: var(--neutral-active-theme);
}

.pagination-bar__text {
  color: var(--neutral-active-theme);
  text-align: center;
  font-size: 16px;
  line-height: 1.375;
}

.pagination-bar__text_type_dots {
  width: 40px;
  color: var(--neutral-active-theme);
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 14px;
}

@media (max-width: 600px) {
  .pagination-bar {
    width: calc(100vw - 32px);
  }
}
