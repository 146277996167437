.auth-button {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1px solid var(--neutral-active-theme);
  color: var(--neutral-active-theme);
  box-sizing: border-box;

  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
}

.auth-button_type_submit {
  background-color: var(--neutral-active-theme);
  color: var(--neutral-contrast-active-theme);
}

.auth-button_color_contrast {
  border-color: var(--main-active-primary);
  background-color: var(--main-active-primary);
  color: var(--neutral-contrast-active);
}

@media screen and (max-width: 780px) {
  .auth-button_mobile_small {
    height: 40px;
    font-size: 12px;
  }
}
